export const getters = {
    list: state => state.list,
    staffAttendanceList: state => state.staffAttendanceList,
    staff_list: state=>state.staff_list,
    inventory: state => state.inventory,
    reasons: state => state.reasons,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    paginationAttendance: state => state.paginationAttendance,
    sort: state => state.sort
};
