<template>
  <div class="home">
      <div
          class="
              dashboard-main-bg
              dashboard-main-bg__full
              dashboard-main-bg_image-filter
              dashboard-main-bg_animated
          "
      ></div>
      <div class="p-pegas mb-5" >
          <el-row :gutter="20">
              <el-col :span="24" >
                  <div class="top-home-filter">
                      <div class="home__flex">
                      <select-branch
                            :class="mode ? 'inputhome__day' : 'inputhome__night'"
                            :id="form.branch_id"
                            v-model="form.branch_id"
                        ></select-branch>
                        <select-cat
                              :class="mode ? 'inputhome__day' : 'inputhome__night'"
                              :items="sortGroup"
                              :placeholder="$t('message.sort_panel')"
                              @sortmethods="sortedMethod"
                              v-model="form.sortedNumber"
                        ></select-cat>
                      </div>
                      <el-row class="button-filter text-right">
                          <el-button
                              size="medium"
                              :class="[activeType == 'daily' ? 'action' : '', mode ? 'text__day3' : 'text__night3']"
                              @click="handleClick('daily')"
                              >
                              {{$t("message.daily")}}
                          </el-button>
                          <el-button
                              size="medium"
                              :class="[activeType == 'weekly' ? 'action' : '', mode ? 'text__day3' : 'text__night3']"
                              @click="handleClick('weekly')"
                              > {{$t("message.weekly")}}
                          </el-button>
                          <el-button
                              size="medium"
                              :class="[activeType == 'monthly' ? 'action' : '', mode ? 'text__day3' : 'text__night3']"
                              @click="handleClick('monthly')"
                              >{{$t("message.monthly")}}
                          </el-button>
                          <el-button
                              size="medium"
                              :class="[activeType == 'yearly' ? 'action' : '', mode ? 'text__day3' : 'text__night3']"
                              @click="handleClick('yearly')"
                              >{{$t("message.yearly")}}
                          </el-button>
                      </el-row>
                  </div>
              </el-col>

          </el-row>
      </div>
      <div  v-can="'DashboardController@attandStaffWeekly'">
        <div  class="p-pegas mb-2 mt-5"  v-can="'DashboardController@attandStaffWeekly'">
            <el-row :gutter="20" v-if="form.sortedNumber == 5">
                <div class="height__top" v-loading="attendstaffweek.length < 1">
                  
                    <ul class="daily__box">
                      <li v-for="(attand_week, index) in attendstaffweek.attand_week" :key="attand_week.day" class="daily__item1" >
                        <div class="daily__header">
                          <span :class="mode ? 'performance__topday' : 'performance__topnight'"> {{ $t("message."+attand_week.day+"") }}</span>
                        </div>
                        <div :class="[ index==(attendstaffweek.attand_week.length-1)?'daily__info green':'daily__info', mode ? 'daily__infoday' : 'daily__infonight']">
                          <div class="daily__squ1">
                            <div class="daily__per1">
                              <span :class="mode ? 'performance__nameday' : 'performance__namenight'">{{ attand_week.procent.toFixed(2) }}%</span>
                            </div>
                            <div class="daily__day1">
                              <span :class="index==(attendstaffweek.attand_week.length-1)?'special__green':''" v-if="attand_week.procent > 30" class="daily__ico">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M17.8 8.79999L13 13.6L9.7 10.3C9.3 9.89999 8.7 9.89999 8.3 10.3L2.3 16.3C1.9 16.7 1.9 17.3 2.3 17.7C2.5 17.9 2.7 18 3 18C3.3 18 3.5 17.9 3.7 17.7L9 12.4L12.3 15.7C12.7 16.1 13.3 16.1 13.7 15.7L19.2 10.2L17.8 8.79999Z" fill="currentColor"/>
                                  <path opacity="0.3" d="M22 13.1V7C22 6.4 21.6 6 21 6H14.9L22 13.1Z" fill="currentColor"/>
                                </svg>
                              </span>
                              <span v-else class="daily__nico">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M19.2 13.8L13.7 8.3C13.3 7.9 12.7 7.9 12.3 8.3L9 11.6L3.7 6.3C3.3 5.9 2.7 5.9 2.3 6.3C1.9 6.7 1.9 7.3 2.3 7.7L8.3 13.7C8.7 14.1 9.3 14.1 9.7 13.7L13 10.4L17.8 15.2L19.2 13.8Z" fill="currentColor"/>
                                  <path opacity="0.3" d="M22 10.9V17C22 17.6 21.6 18 21 18H14.9L22 10.9Z" fill="currentColor"/>
                                </svg>
                              </span>
                              <span :class="mode ? 'performance__topday' : 'performance__topnight'" class="daily__text">{{ attand_week.date }}</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
            </el-row>
        </div>
        <div  class="p-pegas mb-3 mt-2 " v-can="'DashboardController@attandStaffWeekly'">
            <el-row :gutter="20" v-if="form.sortedNumber == 5">
                <div v-loading="attendstaffweek.length < 1" class="height__daily">
                  <ul class="daily__box" >
                    <li v-for="(attand_week, index) in attendstaffweek.attand_week" :key="attand_week.day" class="daily__item">
                      <div :class="[ index==(attendstaffweek.attand_week.length-1)?'daily__info green':'daily__info', mode ? 'daily__infoday' : 'daily__infonight']">
                        <div class="daily__squ">
                          <div>
                            <div class="daily__flex">
                              <span :class="mode ? 'performance__nameday' : 'performance__namenight'" class="daily__per">{{ attand_week.procent.toFixed(2) }}%</span>
                              <span :class="mode ? 'performance__topday' : 'performance__topnight'" class="daily__sec">{{$t("message.coming")}}</span>
                            </div>
                            <div class="daily__flex">
                              <span :class="mode ? 'performance__nameday' : 'performance__namenight'" class="daily__per">{{ (100-attand_week.procent).toFixed(2)}}%</span>
                              <span :class="mode ? 'performance__topday' : 'performance__topnight'" class="daily__sec">{{$t("message.pending")}}</span>
                            </div>
                            <div class="daily__flex">
                              <span :class="mode ? 'performance__nameday' : 'performance__namenight'" class="daily__per">{{attand_week.procent_additional }}%</span>
                              <span :class="mode ? 'performance__topday' : 'performance__topnight'" class="daily__sec">{{$t("message.additional_rest")}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
            </el-row>
        </div>
      </div>
      <div class="p-pegas mb-2 mt-2"  v-can="'DashboardController@getSituationForToday'">
        <el-row :gutter="20" v-if="form.sortedNumber == 1">
          <el-col :span="24">
              <crm-staff-condition :condition="situationfortoday" :title="'Состояние на сегодня'" :branchid="form.branch_id" />
          </el-col>  
        </el-row>
      </div>
      <div class="p-pegas mb-2 mt-2"  v-can="'DashboardController@getRequestsForToday'">
        <el-row :gutter="20" v-if="form.sortedNumber == 1">
          <el-col :span="24">
              <crm-condition :condition="requestsForToday" :title="'Запросы на сегодня'" />
          </el-col>  
        </el-row>
      </div>
      
      <div class="p-pegas mt-3" >
            <el-row :gutter="10">
              <el-col v-if="form.sortedNumber == 1" class="mobile__margintop" :xs="24" :sm="6" v-can="'DashboardController@staffTesting'">
                <dashboard-block :titleStaff="'message.staffTests'" :testStaff="testStaff" />
              </el-col>
              <el-col v-if="form.sortedNumber == 1" class="mobile__margintop" :xs="24" :sm="6" v-can="'DashboardController@staffCurrent'">
                <dashboard-block :titleStaff="'message.operatings'" :testStaff="workingStaff" />
              </el-col>
              <el-col v-if="form.sortedNumber == 1" class="mobile__margintop" :xs="24" :sm="6" v-can="'DashboardController@staffDismissed'">
                <dashboard-block :titleStaff="'message.dismisseds'" :testStaff="firedStaff" />
              </el-col>
              <el-col v-if="form.sortedNumber == 1" class="mobile__margintop" :xs="24" :sm="6" v-can="'DashboardController@enoughStaff'">
                <dashboard-block :titleStaff="'message.enoughStaff'" :testStaff="missingStaff" />
              </el-col>
              <el-col v-if="form.sortedNumber == 1" :xs="24" :sm="8" v-can="'DashboardController@getStaffByMoodStatistic'">
                <div v-if="form.sortedNumber == 1" class="task-home-two-right">
                  <dashboard-mood :staffbymood="staffbymood" :branch_id="form.branch_id" />
                </div>
              </el-col>
              <el-col :xs="24" :sm="16" v-can="'DashboardController@staffReleaseAndReceptionStatistic'">
                  <div v-if="form.sortedNumber == 1" class="task-home-two-right">
                      <div :class="mode ? 'home__day' : 'home__night'" v-loading="releaseandreception.length >= 0" class=" style-scuer
                              d-flex
                              mt-3
                              f-between
                              pl-0
                              pr-0
                              pb-0">
                          <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-3">
                              {{$t("message.arrivals_and_departures")}}
                          </div>
                          <div id="apexChart">
                              <apexchart
                                  type="bar"
                                  height="400"
                                  ref="apexChartBar"
                                  :options="apexChartBar.chartOptions"
                                  :series="apexChartBar.series"
                              ></apexchart>
                          </div>
                      </div>
                  </div>
              </el-col>
              <el-col  v-if="form.sortedNumber == 1"  v-can="'DashboardController@getNewComeStaff'" :span="24">
                <dashboard-personnel :bottom="false" :title="'message.newcomestaffs'" :completestaffprofile="newcomestaffs.newComes" :fixedElem="fixedElem" />
              </el-col> 
              <el-col v-if="form.sortedNumber == 1" v-can="'DashboardController@getCompleteStaffProfile'" :span="24">
                <dashboard-personnel :bottom="true" :title="'message.completestaffprofile'" :completestaffprofile="completestaffprofile.performers" :fixedElem="fixedElem" />
              </el-col> 
              <el-col :xs="24" :sm="16" v-can-or="[
                      'DashboardController@vacancy',
                      'DashboardController@candidate',
                      'DashboardController@staffFromCandidate',
                  ]">
                  <div v-if="form.sortedNumber == 2" class="task-home-two-left">
                      <el-row :gutter="10">
                          <el-col :span="12" v-can="'DashboardController@vacancy'">
                              <div
                                  :class="mode ? 'home__day' : 'home__night'"
                                  class="
                                      style-scuer style-scuer2
                                      mb-3
                                        mt-3
                                  "
                              >
                                  <i
                                      class="
                                          el-icon-document-copy
                                          bg-color1
                                      "
                                  ></i>
                                  <div class="truncate">
                                      <h2 :class="mode ? 'performance__nameday' : 'performance__namenight'" class="font-weight-bolder">
                                          {{ vacancy }} шт
                                      </h2>
                                      <span :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t('message.accepted_CV')}}</span>
                                  </div>
                              </div>
                          </el-col>
                          <!-- end col -->

                          <el-col :span="12" v-can="'DashboardController@candidate'">
                              <div
                                  :class="mode ? 'home__day' : 'home__night'"
                                  class="
                                      style-scuer style-scuer2
                                      mb-3
                                        mt-3
                                  "
                              >
                                  <i class="fa-solid fa-user-plus bg-color2"></i>
                                  <div class="truncate">
                                      <h2
                                          class="
                                              mb-25
                                              font-weight-bolder
                                          "
                                          :class="mode ? 'performance__nameday' : 'performance__namenight'"
                                      >
                                          {{ candidate.length > 1 ? candidate : '' }} шт
                                      </h2>
                                        <span :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t('message.candidates')}}</span>
                                  </div>
                              </div>
                          </el-col>
                          <!-- end col -->

                          <el-col :span="12" v-can="'DashboardController@staffFromCandidate'">
                              <div
                                  :class="mode ? 'home__day' : 'home__night'"
                                  class="
                                      style-scuer style-scuer2
                                  "
                              >
                                  
                                  <i class="fa-solid fa-user-clock bg-color3"></i>
                                  <div class="truncate">
                                      <h2
                                          class="
                                              mb-25
                                              font-weight-bolder
                                          "
                                          :class="mode ? 'performance__nameday' : 'performance__namenight'"
                                      >
                                          {{
                                              staff_from_candidate.test.count
                                          }}
                                          шт
                                      </h2>
                                        <span :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t('message.staffTest')}}</span>
                                  </div>
                              </div>
                          </el-col>
                          <!-- end col -->

                          <el-col :span="12" v-can="'DashboardController@staffFromCandidate'">
                              <div
                                  :class="mode ? 'home__day' : 'home__night'"
                                  class="
                                      style-scuer style-scuer2
                                  "
                              >
                                  
                                  <i class="fa-solid fa-user-check  bg-color4"></i>
                                  <div class="truncate">
                                      <h2
                                          class="
                                              mb-25
                                              font-weight-bolder
                                          "
                                          :class="mode ? 'performance__nameday' : 'performance__namenight'"
                                      >
                                          {{
                                              staff_from_candidate.working.count
                                          }}
                                          шт
                                      </h2>
                                        <span :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t('message.operating')}}</span>
                                  </div>
                              </div>
                          </el-col>
                          <!-- end col -->
                      </el-row>
                  </div>
              </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@staffSourceStatistic'">
                  <div v-if="form.sortedNumber == 2" class="task-home-two-right">
                      <div :class="mode ? 'home__day' : 'home__night'" v-loading="source.length < 1" class=" style-scuer
                              mt-3
                              d-flex
                              f-between
                              pl-0
                              pr-0
                              pb-0">
                          <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-3 mb-4">
                              {{$t("message.social")}}
                          </div>
                          <div id="chart" class="custom__chart">
                              <apexchart
                                  type="donut"
                                  height="100%"
                                  :class="mode ? 'apex__day' : 'apex__nigth'"
                                  :options="social.chartOptions"
                                  :series="social.series"
                              ></apexchart>
                          </div>
                      </div>
                  </div>
              </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@staffAttendencesStatistic'">
                  <div v-if="form.sortedNumber == 5" class="task-home-two-right">
                      <div
                          :class="mode ? 'home__day' : 'home__night'"
                          class="
                              style-scuer
                              d-flex
                              mt-3
                              f-between
                              pl-0
                              pr-0
                              pb-0
                          "
                      >
                          <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-3">
                              {{$t("message.attendance")}}
                          </div>
                          <div class="el-progress-ceter" >
                              <el-progress
                                  type="dashboard"
                                  :class="mode ? 'progress__day' : 'progress__night'"
                                  :percentage="staffattendences.attendences.propors"
                                  :color="colors"
                                  :stroke-width="8"
                              ></el-progress>
                          </div>
                          <div class="foter-twoblocks d-flex w-100">
                              <div
                                  class="
                                      top-border
                                      border-right
                                      foter-twoblocks-left
                                  "
                              >
                                  <p :class="mode ? 'performance__topday' : 'performance__topnight'" class="card-text2 m-0 p-0 mb-2">
                                      {{$t("message.total_qty")}}
                                  </p>
                                  <h3 class="font-weight m-0 p-0">
                                      {{staffattendences.attendences.all_count}}
                                  </h3>
                              </div>
                                <div
                                  class="
                                      top-border
                                      border-right
                                      foter-twoblocks-left
                                  "
                                  @click="showComeStaffList('come_in',form.branch_id,)"
                              >
                                  <p :class="mode ? 'performance__topday' : 'performance__topnight'" class="card-text2 m-0 p-0 mb-2">
                                      {{$t("message.present")}}
                                  </p>
                                  <h3 class="font-weight m-0 p-0">
                                      {{staffattendences.attendences.come_in}}
                                  </h3>
                              </div>
                              <div
                                  class="
                                      top-border
                                      foter-twoblocks-right
                                  "
                                  @click="showComeStaffList('no_come_in',form.branch_id,)"
                              >
                                  <p :class="mode ? 'performance__topday' : 'performance__topnight'" class="card-text2 m-0 p-0 mb-2">
                                      
                                      {{$t("message.missing")}}
                                  </p>
                                  <h3 class="font-weight m-0 p-0">
                                        {{staffattendences.attendences.not_come_in}}
                                  </h3>
                              </div>
                          </div>
                      </div>
                  </div>
              </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@staffPastBirthdaysStatistic'">
                  <div v-if="form.sortedNumber == 1" class="task-home-two-right">
                      <div :class="mode ? 'home__day' : 'home__night'" v-loading="staffPastBirthdays.length < 1" class="style-scuer
                              mt-3
                              d-flex
                              f-between
                              ">
                          <div class="mb-5">
                              <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-0">
                                  {{$t("message.past_birthdays")}}
                              </div>
                              <div
                                  class="
                                      d-flex
                                      f-between
                                      align-center
                                  "
                              ></div>
                          </div>
                          <div class="birthday-user"
                                  v-for="past in staffPastBirthdays"
                                  :key="past.id"
                              >
                              <div class="birthday-user-info">
                                  <div
                                      
                                      style="display: inherit"
                                  >
                                      <div class="birthday-user--img">
                                          <img
                                              :src="
                                                  past.image
                                                      ? baseUrl +
                                                        past.image
                                                            .path
                                                      : 'img/avatar.jpeg'
                                              "
                                              alt=""
                                          />
                                      </div>
                                      <div
                                          class="
                                              birthday-user-name-pos
                                          "
                                      >
                                          <div
                                              class="
                                                  birthday-user-name
                                              "
                                              :class="mode ? 'performance__nameday' : 'performance__namenight'"
                                          >
                                              {{
                                                  past.name +
                                                  " " +
                                                  past.last_name
                                              }}
                                          </div>
                                          <div
                                              class="
                                                  birthday-user-pos
                                              "
                                              :class="mode ? 'performance__topday' : 'performance__topnight'"
                                          >
                                              {{
                                                  past.position
                                                      ? past.position
                                                            .name
                                                      : ""
                                              }}
                                          </div>

                                            <div
                                              class="
                                                  birthday-user-pos
                                              "
                                              :class="mode ? 'performance__topday' : 'performance__topnight'"
                                          >
                                              {{
                                                  past.id
                                              }}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              
                              <div class="data-birthday" :class="mode ? 'performance__nameday' : 'performance__namenight'">
                                  {{
                                      past.date_of_birth
                                          ? past.date_of_birth
                                          : ""
                                  }}
                              </div>
                          </div>
                      </div>
                  </div>
              </el-col>
              <el-col :xs="24" :sm="16" v-can="'DashboardController@wagesStatistic'">
                  <div v-if="form.sortedNumber == 3" class="task-home-two-right">
                      <div :class="mode ? 'home__day' : 'home__night'" v-loading="wages.length < 1" class="style-scuer
                              mt-3
                              d-flex
                              f-between
                            ">
                          <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-3">
                                {{$t("message.wage")}}
                          </div>
                          <div id="chart">
                              <apexchart
                                  type="area"
                                  height="405"
                                  ref="apexChartSalary"
                                  :key="form.sortedNumber"
                                  :options=" apexChartSalary.chartOptions"
                                  :series="apexChartSalary.series"
                              ></apexchart>
                          </div>
                      </div>
                  </div>
              </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@staffAverageSalaryByBranchesStatistic'">
                  <div v-if="form.sortedNumber == 3" class="task-home-two-right">
                    
                      <div :class="mode ? 'home__day' : 'home__night'" v-loading="averagesalarybybranches.length < 1" class="style-scuer mt-3 f-between">
                          <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-3 mb-4 ">
                              {{$t("message.salary_by_branches")}}
                          </div>
                          <div id="chart">
                              <apexchart
                                  type="donut"
                                  height="400"
                                  width="100%"
                                  :class="mode ? 'apex__day' : 'apex__night'"
                                  :options="filial.chartOptions"
                                  :series="filial.series"
                              ></apexchart>
                          </div>
                      </div>
                  </div>
              </el-col>
              <el-col :span="8" >
                  <div v-if="form.sortedNumber == 3" class="task-home-two-left" v-can="'DashboardController@getHasSalaryStaffStatistic'">
                      <el-row :gutter="24">
                          <el-col :span="12"  style="padding-right:6px" v-can="'DashboardController@getHaveSalaryStaffs'">
                              <div
                                  :class="mode ? 'home__day' : 'home__night'"
                                  class="
                                      style-scuer style-scuer2
                                      mb-3
                                        mt-3
                                  "
                                  @dblclick="showStaffList(form.branch_id, 'haveSalaryStaffs')"
                              > 
                              <i class="fa-solid fa-person-circle-check bg-color3"></i>
                                    <!-- <i class="fa-solid fa-person-walking bg-color3" ></i> -->
                                  <div class="truncate">
                                      <h2 :class="mode ? 'performance__nameday' : 'performance__namenight'" class="font-weight-bolder">
                                        {{hasSalaryStaffsStatistic.haveSalayStaffs}}    
                                      </h2>
                                      <span :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t("message.have_salary")}}</span>
                                  </div>
                              </div>
                          </el-col>
                          <el-col :span="12"  style="padding-left:6px" v-can="'DashboardController@getDoesntHaveSalayStaffs'">
                              <div
                                  :class="mode ? 'home__day' : 'home__night'"
                                  class="
                                      style-scuer style-scuer2
                                      mb-3
                                        mt-3
                                  "
                                  @dblclick="showStaffList(form.branch_id, 'doesntHaveSalayStaffs')"
                              >
                              <i class="fa-solid fa-person-circle-xmark bg-color1"></i>
                              <!-- <i class="fa-solid fa-person-circle-check bg-color4"></i> -->
                              <!-- <i class="fa-solid fa-person-circle-minus bg-color4"></i> -->
                                  <div class="truncate">
                                      <h2
                                          class="
                                              mb-25
                                              font-weight-bolder
                                          "
                                          :class="mode ? 'performance__nameday' : 'performance__namenight'"
                                      >
                                      {{hasSalaryStaffsStatistic.doesntHaveSalayStaffs}}
                                      </h2>
                                      <span :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t("message.dosnt_have_salary")}} </span>
                                  </div>
                              </div>
                          </el-col>

                         
                      </el-row>
                  </div>
              </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@staffTodayBirthdaysStatistic'">
                  <div v-if="form.sortedNumber == 1" class="task-home-two-right">
                      <div :class="mode ? 'home__day' : 'home__night'" v-loading="staffTodaysBirthdays.length < 0" class="style-scuer
                              mt-3
                              d-flex
                              f-between
                              ">
                          <div class="mb-5">
                              <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-0" >
                                {{$t("message.today_birthdays")}}
                              </div>
                          </div>
                          <div class="slide-birthday-big text-center">
                              <el-carousel>
                                  <el-carousel-item
                                      v-for="staffToday in staffTodaysBirthdays"
                                      :key="staffToday.id"
                                      :interval="7000"
                                  >
                                      <div class="slide-birthday">
                                          <!-- <router-link
                                              :to="{
                                                  name: 'profile',
                                                  params: {
                                                      id: staffToday.id,
                                                  },
                                              }"
                                              style="display: inherit"
                                          > -->
                                              <img
                                                  :src="
                                                      staffToday.image
                                                          ? baseUrl +
                                                            staffToday
                                                                .image
                                                                .path
                                                          : 'img/avatar.jpeg'
                                                  "
                                                  alt=""
                                              />
                                              <img
                                                  class="img-p-b"
                                                  src="/img/fireworks.svg"
                                                  alt=""
                                              />
                                              <div
                                                  class="
                                                      birthday-user-name-pos
                                                  "
                                              >
                                                  <div
                                                      class="
                                                          birthday-user-name
                                                      "
                                                      :class="mode ? 'performance__nameday' : 'performance__namenight'"
                                                  >
                                                      {{
                                                          staffToday.name +
                                                          " " +
                                                          staffToday.last_name
                                                      }}
                                                  </div>
                                                  <div
                                                      class="
                                                          birthday-user-pos
                                                      "
                                                      :class="mode ? 'performance__topday' : 'performance__topnight'"
                                                  >
                                                      {{
                                                          staffToday.position
                                                              ? staffToday
                                                                    .position
                                                                    .name
                                                              : ""
                                                      }}
                                                  </div>
                                                    <div
                                                      class="
                                                          birthday-user-pos
                                                      "
                                                      :class="mode ? 'performance__topday' : 'performance__topnight'"
                                                  >
                                                      {{
                                                          staffToday.id
                                                      }}
                                                  </div>
                                              </div>
                                          <!-- </router-link> -->
                                      </div>
                                  </el-carousel-item>
                              </el-carousel>
                          </div>
                      </div>
                  </div>
              </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@staffExpectedBirthdaysStatistic'">
                  <div v-if="form.sortedNumber == 1" class="task-home-two-right">
                      <div v-loading="staffExpectedBirthdays.length < 1" :class="mode ? 'home__day' : 'home__night'" class="style-scuer
                              mt-3
                              d-flex
                              f-between
                              ">
                          <div class="mb-5">
                              <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-0">
                                  {{$t("message.expected_birthdays")}}
                              </div>
                          </div>
                          <div
                              class="birthday-user"
                              v-for="expected in staffExpectedBirthdays"
                              :key="expected.id"
                          >
                              <div class="birthday-user-info">
                                  <div
                                      
                                      style="display: inherit"
                                  >
                                      <div class="birthday-user--img">
                                          <img
                                              :src="
                                                  expected.image
                                                  ? baseUrl + expected.image.path
                                                  : 'img/avatar.jpeg'
                                              "
                                              alt=""
                                          />
                                      </div>
                                      <div class="birthday-user-name-pos">
                                          <div class="birthday-user-name"
                                              :class="mode ? 'performance__nameday' : 'performance__namenight'">
                                              {{
                                                  expected.name +
                                                  " " +
                                                  expected.last_name
                                              }}
                                          </div>
                                          <div class="birthday-user-pos"
                                              :class="mode ? 'performance__topday' : 'performance__topnight'">
                                              {{
                                                  expected.position
                                                      ? expected
                                                            .position
                                                            .name
                                                      : ""
                                              }}
                                          </div>
                                          <div
                                              class="
                                                  birthday-user-pos
                                              "
                                              :class="mode ? 'performance__topday' : 'performance__topnight'"
                                          >
                                              {{
                                                  expected.id
                                              }}
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="data-birthday" :class="mode ? 'performance__nameday' : 'performance__namenight'">
                                  <img
                                      src="/img/confetti.svg"
                                      alt=""
                                  />
                                  {{
                                      expected.date_of_birth
                                          ? expected.date_of_birth
                                          : ""
                                  }}
                              </div>
                          </div>
                      </div>
                  </div>
              </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@staffExpectedBirthdaysStatistic'">
                <div v-if="form.sortedNumber == 1" class="task-home-two-right">
                    <div v-loading="staffWithTotalWork&&staffWithTotalWork.length < 1" :class="mode ? 'home__day' : 'home__night'" class="style-scuer
                            mt-3
                            d-flex
                            f-between
                            ">
                            <span class="for-icon" style="position: absolute;  
                            right: 30px;
                            top: 0;
                            font-size: 50px;
                            color: #e98407e6;"><i class="fas fa-medal"></i></span>
                        <div class="mb-5">
                            <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-0">
                                {{$t("Топ 10 лучших сотрудников")}}
                            </div>
                        </div>
                        <div style="max-heihgt:100%;   max-height: 100%;
                                    overflow-y: scroll;
                                    overflow-x: hidden;">
                          <div
                            class="birthday-user mr-2"
                            v-for="expected in staffWithTotalWork"
                            :key="expected.id"
                              >
                            <div class="birthday-user-info">
                                <div
                                    
                                    style="display: inherit"
                                >
                                    <div class="birthday-user--img">
                                        <img
                                            :src="
                                                expected.image
                                                ? baseUrl + expected.image.path
                                                : 'img/avatar.jpeg'
                                            "
                                            alt=""
                                        />
                                    </div>
                                    <div class="birthday-user-name-pos">
                                        <div class="birthday-user-name"
                                            :class="mode ? 'performance__nameday' : 'performance__namenight'">
                                            {{
                                                expected.name +
                                                " " +
                                                expected.last_name
                                            }}
                                        </div>
                                        <div class="birthday-user-pos"
                                            :class="mode ? 'performance__topday' : 'performance__topnight'">
                                            {{
                                                expected.position
                                                    ? expected
                                                          .position
                                                          .name
                                                    : ""
                                            }}
                                        </div>
                                        <div
                                            class="
                                                birthday-user-pos
                                            "
                                            :class="mode ? 'performance__topday' : 'performance__topnight'"
                                        >
                                            {{
                                                expected.id
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="data-birthday" :class="mode ? 'performance__nameday' : 'performance__namenight'">
                                {{
                                  generateTime(expected.total_working_hour)
                                }}
                            </div>
                          </div>
                        </div>
                       
                    </div>
                    
                </div>
            </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@staffAgeStatistic'">
                  <div v-if="form.sortedNumber == 1"  class="task-home-two-right">
                      <div v-loading="staffagestatistic.length < 1" :class="mode ? 'home__day' : 'home__night'" class=" style-scuer
                              d-flex
                              mt-3
                              f-between
                              pl-0
                              pr-0
                              pb-0">
                          <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-3 mb-5">
                            {{$t("message.average_age")}}
                          </div>
                          <div id="chart" class="mt-2 custom__chart" >
                              <apexchart
                                  type="pie"
                                  height="100%"
                                  :class="mode ? 'apex__day' : 'apex__night'"
                                  :options="age.chartOptions"
                                  :series="age.series"
                              ></apexchart>
                          </div>
                      </div>
                  </div>
              </el-col>
              
              <el-col :xs="24" :sm="8" v-can="'DashboardController@staffCountByEducationStatistic'">
                  <div v-if="form.sortedNumber == 1" class="task-home-two-right">
                      <div v-loading="staffbyreason.length < 1" :class="mode ? 'home__day' : 'home__night'" class="style-scuer
                              mt-3
                              pl-0
                              pr-0
                              pb-0 " >
                          <div class="title-new-home-page ml-3 mb-4" :class="mode ? 'performance__topday' : 'performance__topnight'">
                                {{$t("message.education")}}
                          </div>
                          <div id="chart" class="custom__chart" v-if="education.series.length>0">
                              <apexchart
                                  type="donut"
                                  height="100%"
                                  :options="education.chartOptions"
                                  :series="education.series"
                              ></apexchart>

                          </div>
                          <div  class="epty_data"  v-else>
                              <img v-if="mode" src="/img/dashboard.png" alt="">
                              <h4 :class="mode ? 'performance__nameday' : 'performance__namenight'">{{$t("message.not_data")}}</h4>
                          </div>
                      </div>
                  </div>
              </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@staffByReasonStatistic'">
                  <div v-if="form.sortedNumber == 1" class="task-home-two-right">
                      <div v-loading="staffbyreason.length < 1" :class="mode ? 'home__day' : 'home__night'" class=" style-scuer
                              mt-3
                              d-flex
                              f-between
                              pb-0
                              pl-0
                              pr-0">
                          <div class="title-new-home-page ml-3 mb-1" :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t("message.reasons_for_layoffs")}}</div>
                          <div id="chart" class="mt-2 custom__chart">
                              <apexchart
                                  type="pie"
                                  width="100%"
                                  :options="reason.chartOptions"
                                  :series="reason.series"
                              ></apexchart>
                          </div>
                      </div>
                  </div>
              </el-col>
             
              <el-col :xs="24" :sm="8" v-can="'DashboardController@staffFaceIdRegistrationStatistic'">
                  <div v-if="form.sortedNumber == 5" class="task-home-two-right">
                      <div
                          :class="mode ? 'home__day' : 'home__night'"
                          class="
                              style-scuer
                              mt-3
                              d-flex
                              f-between
                              pl-0
                              pr-0
                              pb-0
                          "
                      >
                          <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-3">
                              {{$t("message.faceidregistration")}}
                          </div>
                          <div class="el-progress-ceter">
                              <el-progress
                                  type="dashboard"
                                  :percentage="faceidregistration.zctecoUsers.propors"
                                  :color="colors"
                                  :class="mode ? 'progress__day' : 'progress__night'"
                                  :stroke-width="8"
                              ></el-progress>
                          </div>
                          <div class="foter-twoblocks d-flex w-100">
                              <div
                              
                                  class="
                                      top-border
                                      border-right
                                      foter-twoblocks-left
                                  "
                              >
                                  <p :class="mode ? 'performance__topday' : 'performance__topnight'" class="card-text2 m-0 p-0 mb-2">
                                      {{$t("message.total_qty")}}
                                  </p>
                                  <h3 class="font-weight m-0 p-0">
                                      {{faceidregistration.zctecoUsers.all_count}}
                                  </h3>
                              </div>
                                <span  @click="showStaffList(form.branch_id, 'check_in')"
                                  class="
                                      top-border
                                      border-right
                                      foter-twoblocks-left
                                  "
                                
                              >
                                  <p :class="mode ? 'performance__topday' : 'performance__topnight'" class="card-text2 m-0 p-0 mb-2"  >
                                      {{$t("message.registered")}}
                                  </p>
                                  <h3 class="font-weight m-0 p-0">
                                      {{faceidregistration.zctecoUsers.come_in}}
                                  </h3>
                              </span>
                              <div
                                  class="
                                      top-border
                                      foter-twoblocks-right
                                  "
                                    @click="showStaffList(form.branch_id, 'no_check_in')"
                              >
                                  <p :class="mode ? 'performance__topday' : 'performance__topnight'" class="card-text2 m-0 p-0 mb-2">
                                      {{$t("message.not_registered")}}
                                  </p>
                                  <h3 class="font-weight m-0 p-0">
                                        {{faceidregistration.zctecoUsers.not_come_in}}
                                  </h3>
                              </div>
                          </div>
                      </div>
                  </div>
              </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@telegramStaff'">
                <div v-if="form.sortedNumber == 5" class="task-home-two-left">
                    <el-row :gutter="24">
                        <el-col :span="12"  style="padding-right:6px" v-can="'DashboardController@comeTelegramAnswer'">
                            <div
                                :class="mode ? 'home__day' : 'home__night'"
                                class="
                                    style-scuer style-scuer2
                                    mb-3
                                      mt-3
                                "
                            > 
                                  <i class="fa-solid fa-person-walking bg-color3" ></i>
                                <div class="truncate">
                                    <h2 :class="mode ? 'performance__nameday' : 'performance__namenight'" class="font-weight-bolder">
                                      {{ stafftelegraminformation.staff_bot_telegram_information.coming }}
                                      
                                    </h2>
                                    <span :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t("message.will_arrive")}}</span>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12"  style="padding-left:6px" v-can="'DashboardController@notComeTelegramAnswer'">
                            <div
                                :class="mode ? 'home__day' : 'home__night'"
                                class="
                                    style-scuer style-scuer2
                                    mb-3
                                      mt-3
                                "
                            >
                            <i class="fa-solid fa-person-circle-minus bg-color4"></i>
                                <div class="truncate">
                                    <h2
                                        class="
                                            mb-25
                                            font-weight-bolder
                                        "
                                        :class="mode ? 'performance__nameday' : 'performance__namenight'"
                                    >
                                          {{ stafftelegraminformation.staff_bot_telegram_information.not_coming }}
                                    </h2>
                                    <span :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t("message.not_come")}} </span>
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="12"  style="padding-right:6px" v-can="'DashboardController@waitingAnswer'">
                            <div
                                :class="mode ? 'home__day' : 'home__night'"
                                class="
                                    style-scuer style-scuer2
                                "
                            >
                              <i class="fa-solid fa-user-clock bg-color2"></i>
                                <div class="truncate" @click="showStaffList(form.branch_id, 'waiting_answer_telegram')">
                                    <h2
                                        class="
                                            mb-25
                                            font-weight-bolder
                                        "
                                        :class="mode ? 'performance__nameday' : 'performance__namenight'"
                                    >
                                          {{ stafftelegraminformation.staff_bot_telegram_information.waiting }}
                                    </h2>
                                    <span :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t("message.answer_expected")}}</span>
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="12" style="padding-left:6px" v-can="'DashboardController@noTelegramStaff'">
                            <div
                                :class="mode ? 'home__day' : 'home__night'"
                                class="
                                    style-scuer style-scuer2
                                "
                            >
                                
                                <i class="fa-solid fa-person-circle-xmark bg-color1"></i>
                                <div class="truncate"   @click="showStaffList(form.branch_id, 'not_connect_telegram')">
                                    <h2
                                        class="
                                            mb-25
                                            font-weight-bolder
                                        "
                                        :class="mode ? 'performance__nameday' : 'performance__namenight'"
                                    >
                                          {{ stafftelegraminformation.staff_bot_telegram_information.not_connection}}
                                    </h2>
                                    <span :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t("message.not_registered")}}</span>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
              </el-col>
              <el-col :xs="24" :sm="16" v-can="'DashboardController@staffCompleteProfileFilialStatistic'">
                  <div v-if="'DashboardController@staffCompleteProfileFilialStatistic'">

                    <div v-if="form.sortedNumber == 1" class="task-home-two-right">
                        <div 
                        :class="mode ? 'home__day' : 'home__night'"
                        v-loading="completeprofilethefiliale.length < 1" 
                        class="
                                style-scuer
                                mt-3
                                d-flex
                                f-between
                                pl-0
                                pr-0
                                pb-0
                            ">
                            <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-3">
                                {{$t("message.compleinProfile")}}
                            </div>
                            <div id="compleinProfile">
                                <apexchart
                                    type="bar"
                                    height="400"
                                    ref="compleinProfile"
                                    :options="compleinProfile.chartOptions"
                                    :series="compleinProfile.series"
                                  ></apexchart>
                            </div>
                        </div>
                    </div>
                </div>

              </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@staffWorkTypeStatistic'">
                <div v-if="form.sortedNumber == 1" class="task-home-two-right">
                    <div v-loading="staffworktype.length < 1" :class="mode ? 'home__day' : 'home__night'" class="style-scuer mt-3 d-flex f-between">
                        <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page mb-4 ml-3">
                            {{$t("message.official_and_unofficial")}}
                        </div>
                        <div id="chart" class="custom__chart">
                            <apexchart
                                type="donut"
                                height="100%"
                                :class="mode ? 'apex__day' : 'apex__night'"
                                :options="types.chartOptions"
                                :series="types.series"
                            ></apexchart>
                        </div>
                    </div>
                </div>
            </el-col>
             
  
              <el-col :xs="24" :sm="16" class="salom" v-can="'DashboardController@staffCountByRegionStatistic'">
                  <div v-if="form.sortedNumber == 1" class="task-home-two-right">
                      <div 
                      v-loading="employessbyregion.length < 1"
                      :class="mode ? 'home__day' : 'home__night'" 
                      class="
                              style-scuer
                              mt-3
                              d-flex
                              pl-0
                              pr-0
                              pb-0
                              box__relative
                          ">
                          <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-3">
                            {{$t("message.districtByStaff")}}
                          </div>
                          <div class="overflow__scroll1" id="districtByStaff">
                              <apexchart
                                  :key="mode"
                                  type="bar"
                                  :height="adaptiveSize"
                                  :class="mode ? 'apex__day' : 'apex__night'"
                                  ref="districtByStaff"
                                  :options="districtByStaff.chartOptions"
                                  :series="districtByStaff.series"
                              ></apexchart>
                          </div>
                          <div class="personcount__position">
                            <div class="personcount__box">
                              <div class="personcount__item" @click="testlabel('1')">
                                <span class="personcount__blue"></span>
                                <span class="personcount__text" :class="mode ? 'performance__topday' : 'performance__topnight'">Испытательные</span>
                              </div>
                              <div class="personcount__item">
                                <span class="personcount__green"></span>
                                <span class="personcount__text" :class="mode ? 'performance__topday' : 'performance__topnight'">Действующие</span>
                              </div>
                              <div class="personcount__item">
                                <span class="personcount__red"></span>
                                <span class="personcount__text" :class="mode ? 'performance__topday' : 'performance__topnight'">Уволенные</span>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@staffWorkExperienceStatistic'">
                <div v-if="form.sortedNumber == 1" class="task-home-two-right">
                  
                    <div 
                    :class="mode ? 'home__day' : 'home__night'"
                    v-loading="workexperience.length < 1"
                    class="
                            style-scuer
                            mt-3
                            d-flex
                            f-between
                            pl-0
                            pr-0
                            pb-0
                        ">
                        <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-3">
                            {{$t("message.worksPlace")}}
                        </div>
                        <div id="worksPlace">
                            <apexchart
                                type="bar"
                                height="400"
                                ref="worksPlace"
                                :options="worksPlace.chartOptions"
                                :series="worksPlace.series"
                            ></apexchart>
                        </div>
                    </div>
                </div>
            </el-col>
              <el-col :xs="24" :sm="16" v-can="'DashboardController@visitor'">
                  <div v-if="form.sortedNumber == 4" class="task-home-two-right">
                      <div :class="mode ? 'home__day' : 'home__night'" v-loading="visitors.length < 1"  class=" style-scuer
                              d-flex
                              mt-3
                              f-between
                              pl-0
                              pr-0
                              pb-0">
                          <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-3">
                               {{$t("message.number_of_visitors_periods")}}
                          </div>
                          <div id="apexChart">
                              <apexchart
                                  type="bar"
                                  height="400"
                                  ref="visitorsChart"
                                  :options="visitorsChart.chartOptions"
                                  :series="visitorsChart.series"
                              ></apexchart>
                          </div>
                      </div>
                  </div>
              </el-col>

              <el-col :xs="24" :sm="8" v-can="'DashboardController@topManagers'">
                  <div  v-if="form.sortedNumber == 4" class="task-home-two-right" >
                    
                      <div v-loading="topmanagers.length < 1" :class="mode ? 'home__day' : 'home__night'" class=" style-scuer
                              mt-3
                              d-flex
                              f-between
                              pl-0
                              pr-0">
                          <div class="title-new-home-page ml-3 mb-1" :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t("message.visitors_to_company_employees")}}</div>
                          <div id="chart" class="mt-2" v-if="topManagers.series.length" style="height:90%">
                              <apexchart
                                  type="pie"
                                  height="100%"
                                  ref="topManagers"
                                  :options="topManagers.chartOptions"
                                  :series="topManagers.series"
                              ></apexchart>
                          </div>
                            <div  class="epty_data"  v-else>
                              <img v-if="mode" src="/img/dashboard.png" alt="">
                              <h4 :class="mode ? 'performance__nameday' : 'performance__namenight'">{{$t("message.not_data")}}</h4>
                          </div>
                      </div>
                  </div>
              </el-col>

              <el-col :xs="24" :sm="16" v-can="'DashboardController@employeeKppStatistic'">
                  <div v-if="form.sortedNumber == 4" class="task-home-two-right">
                      <div :class="mode ? 'home__day' : 'home__night'"   class=" style-scuer
                              d-flex
                              mt-3
                              f-between
                              pl-0
                              pr-0
                              pb-0">
                          <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page ml-3">
                              {{$t("message.employee_kpp")}}
                          </div>
                          <div id="employeeKpps">
                              <apexchart
                                  type="bar"
                                  height="400"
                                  ref="employeeKpps"
                                  :options="employeeKpps.chartOptions"
                                  :series="employeeKpps.series"
                              ></apexchart>
                          </div>
                      </div>
                  </div>
              </el-col>

              <el-col :xs="24" :sm="8" v-can="'DashboardController@transportStatistic'">
                  <div v-if="form.sortedNumber == 4" class="task-home-two-right" >
                    
                      <div v-loading="transportkpp.length < 1" :class="mode ? 'home__day' : 'home__night'" class=" style-scuer
                              mt-3
                              d-flex
                              f-between
                              pl-0
                              pr-0">
                          <div class="title-new-home-page ml-3 mb-1" :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t("message.active_machines")}}</div>
                          <div id="chart" class="mt-2" v-if="transportList.series.length" style="height:90%">
                              <apexchart
                                  type="pie"
                                  height="100%"
                                  ref="transportList"
                                  :options="transportList.chartOptions"
                                  :series="transportList.series"
                              ></apexchart>
                          </div>
                            <div  class="epty_data"  v-else>
                              <img v-if="mode" src="/img/dashboard.png" alt="">
                              <h4 :class="mode ? 'performance__nameday' : 'performance__namenight'">{{$t("message.not_data")}}</h4>
                          </div>
                      </div>
                  </div>
              </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@operationByReasons'">
                  <div  v-if="form.sortedNumber == 4"  class="task-home-two-right" >
                    
                      <div v-loading="reasonbyoperation.length < 1" :class="mode ? 'home__day' : 'home__night'" class=" style-scuer
                              mt-3
                              d-flex
                              f-between
                              pl-0
                              pr-0">
                          <div class="title-new-home-page ml-3 mb-1" :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t("message.causes_operating_system")}}</div>
                          <div id="chart" class="mt-2" v-if="reasonByOperationChart.series.length" style="height:90%">
                              <apexchart
                                  type="pie"
                                  height="100%"
                                  
                                  ref="reasonByOperationChart"
                                  :options="reasonByOperationChart.chartOptions"
                                  :series="reasonByOperationChart.series"
                              ></apexchart>
                          </div>
                            <div  class="epty_data"  v-else>
                              <img v-if="mode" src="/img/dashboard.png" alt="">
                              <h4 :class="mode ? 'performance__nameday' : 'performance__namenight'">{{$t("message.not_data")}}</h4>
                          </div>
                      </div>
                  </div>
              </el-col>

               <el-col :xs="24" :sm="16" v-can="'DashboardController@tmsStatistic'">
                  <div  v-if="form.sortedNumber == 4"  class="task-home-two-right" >
                    
                      <div v-loading="tmsstatistic.length < 1" :class="mode ? 'home__day' : 'home__night'" class=" style-scuer
                              mt-3
                              d-flex
                              f-between
                              pl-0
                              pr-0">
                          <div class="title-new-home-page ml-3 mb-1" :class="mode ? 'performance__topday' : 'performance__topnight'"> {{$t("message.tms")}}</div>
                          <div id="chart" class="mt-2" v-if="tmsChart.series.length" >
                                <apexchart 
                                    type="bar" 
                                    height="400" 
                                     ref="tmsChart"
                                    :options="tmsChart.chartOptions" 
                                    :series="tmsChart.series"
                                ></apexchart>
                          </div>
                            <div  class="epty_data"  v-else>
                              <img v-if="mode" src="/img/dashboard.png" alt="">
                              <h4 :class="mode ? 'performance__nameday' : 'performance__namenight'">{{$t("message.not_data")}}</h4>
                          </div>
                      </div>
                  </div>
              </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@tmsList'">
                  <div  v-if="form.sortedNumber == 4"  class="task-home-two-right" >
                    
                      <div v-loading="tmsList.length < 1" :class="mode ? 'home__day' : 'home__night'" class=" style-scuer
                              mt-3
                              d-flex
                              f-between
                              pl-0
                              pr-0">
                          <div class="title-new-home-page ml-3 mb-1" :class="mode ? 'performance__topday' : 'performance__topnight'">{{$t("message.info_tms_statistic")}}</div>
                          <div id="chart" class="mt-2" style="height:90%">
                             <div class="agents__half task-scroll-khan" style="height: 100%; overflow-y: scroll;">
                                <table class="agents__table" :class="mode ? 'table__myday' : 'table__mynight'">
                                  <thead>
                                    <tr>
                                      <th>{{$t("message.type")}}</th>
                                      <th>{{$t('message.quantity')}}</th>
                                      <th>{{$t("message.created_at")}}</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="emp in tmsList" :key="emp.id">
                                      <td class="agents__center">
                                        <span>   {{emp.type == "exit"
                                              ? $t("message.exit")
                                              : $t("message.entrance")
                                          }} </span></td>
                                      <td class="agents__center">{{emp.items}}</td>
                                      <td class="agents__center">{{emp.created_at}}</td>
                                      <td class="agents__view"><span class="el-icon-view"  @click="showTmsInfo(emp.id)"></span></td>
                                    </tr>
                                  </tbody>
                                </table>
                               </div>
                          </div>
                      </div>
                  </div>
              </el-col>
              <el-col :xs="24" :sm="8" v-can="'DashboardController@staffGenderStatistic'">
                  <div v-if="form.sortedNumber == 5" class="task-home-two-right">
                      <div :class="mode ? 'home__day' : 'home__night'" v-loading="genderstatistic.length < 1" class="style-scuer
                              mt-3
                              d-flex
                              f-between
                            ">
                          <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page mb-4">
                              {{$t("message.count_gender")}}
                          </div>
                          <div id="chart" class="custom__chart">
                              <apexchart
                                  type="donut"
                                  height="100%"
                                  :class="mode ? 'chart__day' : 'chart__night'"
                                  :options="gender.chartOptions"
                                  :series="gender.series"
                              ></apexchart>
                          </div>
                      </div>
                  </div>
              </el-col>
             
            
      </el-row>
    </div>

  <el-drawer
    title="I'm outer Drawer"
    :visible.sync="showUseerModal"
    size="30%"
    :with-header="false"
    ref="show-users"
    @opened="drawerOpened('show-users')"
  >
    <div>
      <show-users-home
        :staff="selected"
        @closeUserModal="closeUserModal"
        :child="child"
        ref="show-users"
      ></show-users-home>
    </div>
  </el-drawer>
  <el-drawer
    title="I'm outer Drawer"
    :visible.sync="showComeUseerModal"
    size="30%"
    :with-header="false"
    ref="show-users-come"
    @opened="drawerTwoOpened('show-users-come')"
  >
    <div>
      <show-users-come
        :staff="selected"
        @closeUserModal="closeComeUserModal"
        :child_come="child_come"
        ref="show-users-come"
      ></show-users-come>
    </div>
  </el-drawer>

   <el-drawer
    title="I'm outer Drawer"
    :visible.sync="showMostToiletStaffModal"
    size="55%"
    :with-header="false"
    ref="show-tms"
    @opened="drawerMostToiletStaffOpened('show-most-toilet-staff')"
  >
    <div>
      <show-most-toilet-staff
        :staff="selected"
        @closeMostToiletStaffModal="closeMostToiletStaffModal"
        :child="staff"
        ref="show-most-toilet-staff"
      ></show-most-toilet-staff>
    </div>
  </el-drawer>
  <el-drawer
    title="I'm outer Drawer"
    :visible.sync="showTmsModal"
    size="45%"
    :with-header="false"
    ref="show-tms"
    @opened="drawerTmsOpened('show-tms')"
  >
    <div>
      <show-tms
        :staff="selected"
        @closeTmsModal="closeTmsModal"
        :child="child"
        ref="show-tms"
      ></show-tms>
    </div>
  </el-drawer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
import selectBranch from "@/components/filters/inventory/select-branch";
import crmCondition from "@/components/crm/crm-condition"
import crmStaffCondition from "@/components/crm/crm-staff-condition"
import selectCat from "@/components/filters/inventory/select-cat";
import showUsersHome from "./show-users-home.vue";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import ApexCharts from "apexcharts";
import showUsersCome from './show-users-come.vue';
import showTms from './show-tms.vue';
import showMostToiletStaff from './show-most-toilet-staff.vue';
import dashboardMood from "@/components/dashboard/dashboard-mood";
import dashboardBlock from "@/components/dashboard/dashboard-block";
import dashboardPersonnel from "@/components/dashboard/dashboard-personnel"

export default {
  name: "Home",
  components: {
      ApexCharts,
      selectBranch,
      showUsersHome,
      selectCat,
      showUsersCome,
      showTms,
      Splide,
      SplideSlide,
      showMostToiletStaff,
      crmCondition,
      dashboardMood,
      dashboardBlock,
      dashboardPersonnel,
      crmStaffCondition
  },
  data: () =>  ({
          loader: true,
          fixedElem: 7,
          windowWidth: window.innerHeight,
          requests: [
            {
                title: 'Запросы на отгулы',
                quantity: '8',
                id: 0,
                per: true
            },
            {
                title: 'Запросы аванса',
                quantity: '15',
                id: 1,
                per: false
            },
            {
                title: 'Штрафы',
                quantity: '4',                
                id: 2,
                per: true
            },
            {
                title: 'Премии',
                quantity: '15',
                id: 3,
                per: false
            },
            {
                title: 'Жалобы',
                quantity: '5',
                id: 4,
                per: true
            },
            {
                title: 'Объясн-ая письмо',
                quantity: '2',
                id: 5,
                per: true
            },
            {
                title: 'Заяв-ая письмо',
                quantity: '2',
                id: 7,
                per: false
            },
          ],
          condition: [
            {
                title: 'На работе',
                quantity: '227',
                id: 0,
                per: true
            },
            {
                title: 'Не пришли',
                quantity: '17',
                id: 1,
                per: true
            },
            {
                title: 'Больничные',
                quantity: '7',                
                id: 2,
                per: true
            },
            {
                title: 'В отпуске',
                quantity: '2',
                id: 3,
                per: false
            },
            {
                title: 'В командировке',
                quantity: '5',
                id: 4,
                per: true
            },
            {
                title: 'Опаздавшие',
                quantity: '8',
                id: 5,
                per: false
            },
            {
                title: 'Отпросились',
                quantity: '8',
                id: 7,
                per: true
            },
          ],
          sortedNumber:1,
          primaryOptions: {
            type: "loop",
            perPage: 7,
            perMove: 1,
            pagination: false,
            gap: "10px",
            fixedWidth: "160px",
            arrows: true,
            fixedHeight: "240px",
            focus: "center",
            trimSpace: false,
            //cover: true,
            padding: {
              right: "10px",
              left: "10px"
            }
          },
          sortGroup: [
            {
              name: 'message.sort_panel',
              id: 1
            },
            {
              name: 'message.recruiting',
              id: 2
            },
            {
              name: 'message.finance',
              id: 3
            },
            {
              name: 'message.KPP',
              id: 4
            },
            {
              name: 'message.indicators',
              id: 5
            },
          ],
          form: {
              branch_id: null,
              sortedNumber: 1,
          },
          child: {},
          adaptiveSize: 1500,
          
          child_come:{},
          staff:{},
          showUseerModal: false,
          showComeUseerModal:false,
          showMostToiletStaffModal:false,
          showTmsModal:false,
          selected: null,
          attendences:null,
          region: "",
          activeType: "daily",
          loadStatistics: true,
          radio1: "ПО бюджету",
          percentage: 75,
          baseUrl: process.env.VUE_APP_URL.substring(
              0,
              process.env.VUE_APP_URL.length - 3
          ),
          gender: {
              series: [],
              chartOptions: {
                stroke: {
                  show: true,
                  curve: 'smooth',
                  lineCap: 'butt',
                  colors: undefined,
                  width: 2,
                  dashArray: 0,      
                },
                colors: ['#0d67c9', '#008A5B', '#FF7E6A', '#E69C24', '#7881E3', '#EEE8A9', '#d82610'],
                chart: {
                    type: "donut",
                },
                legend: {
                  width: '100%',
                  position: 'bottom',
                  labels: {
                      colors: '#fff',
                  },
                },
                labels: [],
                responsive: [
                    {
                        breakpoint: 450,
                        options: {
                            chart: {
                                width: '100%',
                            },
                            legend: {
                              width: '100%',
                              position: 'bottom',
                            },
                        },
                    },
                ],
              },
          },
          types: {
              series: [],
              chartOptions: {
                  chart: {
                      type: "donut",
                  },
                  colors: ['#0d67c9', '#008A5B', '#FF7E6A', '#E69C24', '#7881E3', '#EEE8A9', '#d82610'],
                  stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 2,
                    dashArray: 0,      
                  },
                  legend: {
                    width: '100%',
                    position: 'bottom',
                    labels: {
                      colors: '#fff',
                    },
                  },
                  labels: [],
                  responsive: [
                      {
                          breakpoint: 450,
                          options: {
                              chart: {
                                  width: '100%',
                              },
                              legend: {
                                width: '100%',
                                position: 'bottom'
                              },
                          },
                      },
                  ],
              },
          },
          districtByStaff:{
              chartID: "districtByStaff",
              series: [
                  {
                      name: 'Испытательные',
                      data: []
                  }, 
                  {
                      name: 'Действующие',
                      data: []
                  }, 
                  {
                      name: 'Уволенные',
                      data: []
                  }
              ],
            chartOptions: {
              chart: {
                type: 'bar',
               
                stacked: true,
                foreColor: '#fff',
                toolbar: {
                  show: false
                },
              },
              colors: ['#0d67c9', '#008A5B', '#d82610'],
              plotOptions: {
                bar: {
                  horizontal: true,
                  endingShape: "rounded",
                  height: '60px',
                  borderRadius: 4,
                  dataLabels: {
                    position: 'top',
                  },
                },
              },
              responsive: [
                  {
                      breakpoint: 450,
                      options: {
                          chart: {
                            height: '97%'
                          },
                          legend: {
                            width: '100%',
                            position: 'bottom'
                          },
                      },
                  },
              ],
              grid: {
                  show: true,
                  borderColor: '#fff',
                  strokeDashArray: 0,
                  position: 'back'
              },
              stroke: {
                      show: true,
                      width: 1,
                      colors: ["transparent"],
                  },
              xaxis: {
                categories: [],
                labels: {
                  formatter: function (val) {
                    return val
                  },
                  style: {
                      colors: "#fff",
                      fontSize: "12px",
                  },
                }
              },
              yaxis: {
                show: true,
                title: {
                  text: undefined
                },
                axisBorder: {
                    show: true,
                    color: '#78909C',
                    offsetX: 20,
                    offsetY: 20
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: '#78909C',
                    offsetX: 12,
                    offsetY: 20
                },
                labels: {
                  align: 'right',
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                      colors: "#fff",
                      fontSize: '12px',
                      fontWeight: 400,
                      cssClass: 'apexcharts-yaxis-label',
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  },
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val
                  }
                }
              },
            
              fill: {
                opacity: 1
              },
              legend: {
                  show: false,
                  showForSingleSeries: false,
                  showForNullSeries: true,
                  showForZeroSeries: true,
                  position: 'bottom',
                  horizontalAlign: 'center', 
                  floating: true,
                  fontSize: '14px',
                  fontFamily: 'Helvetica, Arial',
                  fontWeight: 400,
                  formatter: undefined,
                  inverseOrder: false,
                  width: undefined,
                  height: undefined,
                  tooltipHoverFormatter: undefined,
                  customLegendItems: [],
                  offsetX: -2,
                  offsetY: 0,
                  labels: {
                      colors: undefined,
                      useSeriesColors: false
                  },
                  markers: {
                      width: 12,
                      height: 12,
                      strokeWidth: 0,
                      strokeColor: '#fff',
                      fillColors: undefined,
                      radius: 12,
                      customHTML: undefined,
                      onClick: undefined,
                      offsetX: 0,
                      offsetY: 0
                  },
                  itemMargin: {
                      horizontal: 5,
                      vertical: 0
                  },
                  onItemClick: {
                      toggleDataSeries: true
                  },
                  onItemHover: {
                      highlightDataSeries: true
                  },
              }
            },
          },
          filial: {
              series: [],
              chartOptions: {
                stroke: {
                  show: true,
                  curve: 'smooth',
                  lineCap: 'butt',
                  colors: undefined,
                  width: 2,
                  dashArray: 0,      
                }, 
                plotOptions: {
                  
                  pie: {
                      
                    donut: {
                      
                      labels: {
                        show: true,
                        name: {
                        },
                        total: {
                          show: true,
                          showAlways: false,
                          label: 'Total',
                          fontSize: '12px',
                          fontFamily: 'Helvetica, Arial, sans-serif',
                          fontWeight: 600,
                          color: '#000',
                          formatter: function (w) {
                            return ((w.globals.seriesTotals.reduce((a, b) => {
                              return a + b
                            }, 0) / w.globals.seriesTotals.length)).toLocaleString() + ' сўм'
                          }
                        },
                        
                        value: {
                          show: true,
                          fontSize: '14px',
                          fontFamily: 'Helvetica, Arial, sans-serif',
                          fontWeight: 400,
                          color: '#000',
                          offsetY: 16,
                          formatter: function (val) {
                            return (+val).toLocaleString() +' сўм';
                          }
                        },
                      },
                      
                      
                    },
                  }
                },
                chart: {
                  type: 'donut',
                },
                legend: {
                  width: '100%',
                  position: 'bottom'
                },
                labels: [],
                dataLabels: {
                  enabled: false,
                },
                colors: ['#0d67c9', '#008A5B', '#FF7E6A', '#E69C24', '#7881E3', '#d82610'],
                 tooltip: {
                       y: {
                          formatter: function (val) {
                              return val.toLocaleString() +' сўм';
                          },
                      },
                  },
                responsive: [{
                  breakpoint: 400,
                  options: {
                    chart: {
                      width:200
                    },
                    legend: {
                      position: 'bottom'
                    }
                  }
                }]
              },
          },
          telegramStaff: {
              series: [],
              chartOptions: {
                  
                plotOptions: {
                  
                  pie: {
                      
                    donut: {
                      
                      labels: {
                        show: true,
                        name: {
                        },
                        value: {
                        },
                        total: {
                          show: true,
                          showAlways: false,
                          label: 'Total',
                          fontSize: '12px',
                          fontFamily: 'Helvetica, Arial, sans-serif',
                          fontWeight: 600,
                          color: '#fff',
                          formatter: function (w) {
                            return ((w.globals.seriesTotals.reduce((a, b) => {
                              return a + b
                            }, 0)))
                          }
                        },
                        
                        value: {
                          show: true,
                          fontSize: '14px',
                          fontFamily: 'Helvetica, Arial, sans-serif',
                          fontWeight: 400,
                          color: '#fff',
                          offsetY: 16,
                          formatter: function (val) {
                            return val;
                          }
                        },
                      },
                      
                      
                    },
                  }
                },
                chart: {
                  type: 'donut',
                },
                  legend: {
                      width: '100%',
                      position: 'bottom'
                  },
                labels: [],
                dataLabels: {
                  enabled: false,
                },
                colors: ['#66DA26', '#2E93fA',  '#FF9800', '#d82610', ],
                 tooltip: {
                       y: {
                          formatter: function (val) {
                              return val.toLocaleString() +' сўм';
                          },
                      },
                  },
                responsive: [{
                  breakpoint: 400,
                  options: {
                    chart: {
                      width:200
                    },
                    legend: {
                      position: 'bottom'
                    }
                  }
                }]
              },
          },
          
          colors: [
              { color: "#d82610", percentage: 20 },
              { color: "#e6a23c", percentage: 40 },
              { color: "#008A5B", percentage: 60 },
              { color: "#0d67c9", percentage: 80 },
              { color: "#6f7ad3", percentage: 100 },
          ],
          apexChartBar: {
              chartID: "apexChartBar",
              series: [
                  {
                      name: "Испытательные",
                      data: [],
                  },
                  {
                      name: "Действующие",
                      data: [],
                  },
                  {
                      name: "Уволенные",
                      data: [],
                  },
              ],
              chartOptions: {
                  colors: ["#0d67c9", "#008A5B", "#d82610"],
                  chart: {
                      type: "bar",
                      height: 350,
                  },
                  grid: {
                      show: true,
                      borderColor: '#fff',
                      strokeDashArray: 0,
                      position: 'back'
                  },
                  plotOptions: {
                      bar: {
                          horizontal: false,
                          columnWidth: "55%",
                          endingShape: "rounded",
                          borderRadius: 4,
                      },
                  },
                  dataLabels: {
                      enabled: false,
                      labels: {
                          style: {
                              colors: "#fff",
                              fontSize: "12px",
                          },
                      },
                  },
                  stroke: {
                      show: true,
                      width: 2,
                      colors: ["transparent"],
                  },
                  xaxis: {
                      categories: [],
                      labels: {
                          style: {
                              colors: "#000",
                              fontSize: "12px",
                          },
                      },
                  },
                  yaxis: {
                      title: {
                          text: "",
                      },
                      labels: {
                          style: {
                              colors: "#000",
                              fontSize: "12px",
                          },
                      },
                  },
                  fill: {
                      opacity: 1,
                  },
                  tooltip: {
                      y: {
                          formatter: function (val) {
                              return " " + val + "";
                          },
                      },
                  },
              },
          },
          employeeKpps: {
              chartID: "employeeKpps",
              series: [
                  {
                      name: "План входа",
                      data: [],
                  },
                  {
                      name: "Факт входа",
                      data: [],
                  },
                  
              ],
              chartOptions: {
                  colors: ["#e69c24", "#008A5B"],
                  chart: {
                      type: "bar",
                      height: 350,
                  },
                  grid: {
                      show: true,
                      borderColor: '#fff',
                      strokeDashArray: 0,
                      position: 'back'
                  },
                  plotOptions: {
                      bar: {
                          horizontal: false,
                          columnWidth: "55%",
                          endingShape: "rounded",
                          borderRadius: 4,
                      },
                  },
                  dataLabels: {
                      enabled: false,
                      labels: {
                          style: {
                              colors: "#fff",
                              fontSize: "12px",
                          },
                      },
                  },
                  stroke: {
                      show: true,
                      width: 2,
                      colors: ["transparent"],
                  },
                  xaxis: {
                      categories: [],
                      labels: {
                          style: {
                              colors: "#000",
                              fontSize: "12px",
                          },
                      },
                  },
                  yaxis: {
                      title: {
                          text: "",
                      },
                      labels: {
                          style: {
                              colors: "#000",
                              fontSize: "12px",
                          },
                      },
                  },
                  fill: {
                      opacity: 1,
                  },
                  tooltip: {
                      y: {
                          formatter: function (val) {
                              return " " + val + "";
                          },
                      },
                  },
              },
          },
          visitorsChart: {
              chartID: "visitorsChart",
              series: [
                  {
                      name: "Visitors",
                      data: [],
                  },
                 
              ],
              chartOptions: {
                  colors: ["#0d67c9", "#008A5B", "#d82610"],
                  chart: {
                      type: "bar",
                      height: 350,
                  },
                  grid: {
                      show: true,
                      borderColor: '#fff',
                      strokeDashArray: 0,
                      position: 'back'
                  },
                  plotOptions: {
                      bar: {
                          horizontal: false,
                          columnWidth: "55%",
                          endingShape: "rounded",
                          borderRadius: 4,
                      },
                  },
                  dataLabels: {
                      enabled: false,
                      labels: {
                          style: {
                              colors: "#fff",
                              fontSize: "12px",
                          },
                      },
                  },
                  stroke: {
                      show: true,
                      width: 2,
                      colors: ["transparent"],
                  },
                  xaxis: {
                      categories: [],
                      labels: {
                          style: {
                              colors: "#000",
                              fontSize: "12px",
                          },
                      },
                  },
                  yaxis: {
                      title: {
                          text: "",
                      },
                      labels: {
                          style: {
                              colors: "#000",
                              fontSize: "12px",
                          },
                      },
                  },
                  fill: {
                      opacity: 1,
                  },
                  tooltip: {
                      y: {
                          formatter: function (val) {
                              return " " + val + "";
                          },
                      },
                  },
              },
          },
          worksPlace: {
              chartID: "worksPlace",
              series: [
                  {
                      name: "Сотрудники",
                      data: [],
                  }
                  
              ],
              chartOptions: {
                  colors: ["#0d67c9"],
                  chart: {
                      type: "bar",
                      height: 350,
                  },
                  plotOptions: {
                      bar: {
                          horizontal: false,
                          columnWidth: "55%",
                          endingShape: "rounded",
                          borderRadius: 4,
                      },
                  },
                  grid: {
                      show: true,
                      borderColor: '#fff',
                      strokeDashArray: 0,
                      position: 'back'
                  },
                  dataLabels: {
                      enabled: false,
                      labels: {
                          style: {
                              colors: "#fff",
                              fontSize: "12px",
                          },
                      },
                  },
                  stroke: {
                      show: true,
                      width: 2,
                      colors: ["transparent"],
                  },
                  xaxis: {
                      categories: [],
                      labels: {
                          style: {
                              colors: "#fff",
                              fontSize: "12px",
                          },
                      },
                  },
                  yaxis: {
                      title: {
                          text: "",
                      },
                      labels: {
                          style: {
                              colors: "#fff",
                              fontSize: "12px",
                          },
                      },
                  },
                  fill: {
                      opacity: 1,
                  },
                  tooltip: {
                      y: {
                          formatter: function (val) {
                              return " " + val + "";
                          },
                      },
                  },
              },
          },
          compleinProfile: {
              chartID: "compleinProfile",
              series: [
                  {
                      name: "Сотрудники",
                      data: [],
                  }
                  
              ],
              chartOptions: {
                  colors: ['#0d67c9', '#008A5B', '#FF7E6A', '#E69C24', '#7881E3', '#EEE8A9', '#B01A76'],
                  chart: {
                      type: "bar",
                      stacked: true,
                      foreColor: '#fff'
                  },
                  grid: {
                      show: true,
                      borderColor: '#fff',
                      strokeDashArray: 0,
                      position: 'back'
                  },
                  plotOptions: {
                      bar: {
                          horizontal: true,
                          columnWidth: '70%',
                          barHeight: '70%',
                          endingShape: "rounded",
                          borderRadius: 4,
                        
                      },
                  },
                  dataLabels: {
                      enabled: true,
                      offsetX: -20,
                      labels: {
                          style: {
                              colors: "#fff",
                              fontSize: "12px",
                          },
                      },
                      formatter: function (val, opts) {
                            return " " + val + "%";
                        },
                      
                  },
                  stroke: {
                      show: true,
                      width: 1,
                      colors: ["transparent"],
                  },
                  xaxis: {
                      categories: [],
                      labels: {
                          style: {
                             colors: "#aaa",
                              fontSize: "12px",
                          },
                      },
                  },
                  yaxis: {
                      title: {
                          text: "",
                      },
                      labels: {
                          style: {
                              colors: "#fff",
                              fontSize: "12px",
                          },
                      },
                  },
                  fill: {
                      opacity: 1,
                  },
                  tooltip: {
                      y: {
                          formatter: function (val) {
                              return " " + val + "%";
                          },
                      },
                  },
              },
          },
          apexChartSalary: {
              chartID: "apexChartSalary",
              series: [
                {
                    name: "Salary",
                    data: [],
                },
                {
                    name: "Transactions",
                    data: [],
                },
                   
              ],
              chartOptions: {
                  colors: ['#0d67c9', '#008A5B'],
                  chart: {
                      height: 350,
                      type: "area",
                  },
                  dataLabels: {
                      enabled: false,
                  },
                  stroke: {
                      curve: "smooth",
                  },
                  xaxis: {
                      type: "datetime",
                      categories: [],
                      labels: {
                          style: {
                              colors: "#fff",
                              fontSize: "12px",
                          },
                      },
                  },
                  grid: {
                      show: true,
                      borderColor: '#fff',
                      strokeDashArray: 0,
                      position: 'back'
                  },
                  yaxis: {
                      title: {
                          text: "",
                      },
                      labels: {
                        formatter: function(val , index){
                          return val.toLocaleString()
                        },
                          style: {
                              colors: "#fff",
                              fontSize: "12px",
                          },
                      },
                  },
                  tooltip: {
                       y: {
                          formatter: function (val) {
                              return " " + val.toLocaleString();
                          },
                      },
                  },
              },
          },

          apexChartArea: {
              series: [
                  {
                      name: "Zarplata",
                      data: [12, 80, 50, 20, 75, 40, 60],
                  },
                   {
                      name: "series2",
                      data: [11, 32, 45, 32, 34, 52, 41],
                  },
              ],
              chartOptions: {
                  chart: {
                      height: 350,
                      type: "area",
                  },
                  dataLabels: {
                      enabled: false,
                  },
                  colors: ['#0d67c9', '#008A5B', '#FF7E6A', '#E69C24', '#7881E3', '#EEE8A9', '#B01A76'],
                  stroke: {
                      curve: "smooth",
                  },
                  xaxis: {
                      type: "datetime",
                      categories: [
                          "2018-09-19T00:00:00.000Z",
                          "2018-09-19T01:30:00.000Z",
                          "2018-09-19T02:30:00.000Z",
                          "2018-09-19T03:30:00.000Z",
                          "2018-09-19T04:30:00.000Z",
                          "2018-09-19T05:30:00.000Z",
                          "2018-09-19T06:30:00.000Z",
                      ],
                      labels: {
                          style: {
                              colors: "#fff",
                              fontSize: "12px",
                          },
                      },
                  },
                  yaxis: {
                      title: {
                          text: "",
                      },
                      labels: {
                          style: {
                              colors: "#fff",
                              fontSize: "12px",
                          },
                      },
                  },
                  tooltip: {
                      x: {
                          format: "dd/MM/yy HH:mm",
                      },
                  },
              },
          },
          age: {
              series: [],
              chartOptions: {
                stroke: {
                  show: true,
                  curve: 'smooth',
                  lineCap: 'butt',
                  colors: undefined,
                  width: 2,
                  dashArray: 0,      
                },
                colors: ['#0d67c9', '#008A5B', '#FF7E6A', '#E69C24', '#7881E3', '#EEE8A9', '#B01A76', '#003371', '#9664C6'],
                chart: {
                    width: 340,
                    type: "pie",
                    foreColor: '#000'
                },
                legend: {
                  width: '100%',
                  position: 'bottom',
                  labels: {
                      colors: '#000',
                  },
                },
                labels: [],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: "100%",
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
          },
          reason: {
              series: [],
              chartOptions: {
                  chart: {
                      width: 340,
                      type: "pie",
                  },
                  colors: ['#0d67c9', '#008A5B', '#FF7E6A', '#E69C24', '#7881E3', '#EEE8A9', '#B01A76', '#003371', '#9664C6'],
                  stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 2,
                    dashArray: 0,      
                  },
                  legend: {
                    width: '100%',
                    position: 'bottom',
                    labels: {
                      colors: '#000',
                    },
                  },
                  labels: [],
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                          chart: {
                              width: '100%',
                              height: '100%'
                          },
                          legend: {
                              height: 70,
                              position: "bottom",
                          },
                      },
                    },
                  ],
              },
          },
          topManagers: {
              series: [],
              chartOptions: {
                  chart: {
                      width: '100%',
                      type: "pie",
                  },
                  colors: ['#0d67c9', '#008A5B', '#FF7E6A', '#E69C24', '#7881E3', '#EEE8A9', '#B01A76', '#003371', '#9664C6'],
                  stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    width: 1,
                    dashArray: 0,      
                  },
                  legend: {
                    width: '100%',
                    position: 'right',
                    height: 80,

                  },
                  labels: [],
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                          chart: {
                              width: 200,
                          },
                          legend: {
                              position: "bottom",
                          },
                      },
                    },
                  ],
              },
          },
          toiletAttendants: {
              series: [],
              chartOptions: {
                  chart: {
                      width: '100%',
                      type: "pie",
                  },
                  colors: ['#0d67c9', '#008A5B', '#FF7E6A', '#E69C24', '#7881E3', '#EEE8A9', '#B01A76', '#003371', '#9664C6'],
                  stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    width: 1,
                    dashArray: 0,      
                  },
                  legend: {
                    width: '100%',
                    position: 'right',
                    height: 80,

                  },
                  labels: [],
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                          chart: {
                              width: 200,
                          },
                          legend: {
                              position: "bottom",
                          },
                      },
                    },
                  ],
                  tooltip: {
                      y: {
                          formatter: function (val) {
                              return " " + val + " minut";
                          },
                      },
                  },
              },
          },
          transportList: {
              series: [],
              chartOptions: {
                  chart: {
                      width: '100%',
                      type: "pie",
                  },
                  colors: ['#0d67c9', '#008A5B', '#FF7E6A', '#E69C24', '#7881E3', '#EEE8A9', '#B01A76', '#003371', '#9664C6'],
                  stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    width: 1,
                    dashArray: 0,      
                  },
                  legend: {
                    width: '100%',
                    position: 'right',
                    height: 80,

                  },
                  labels: [],
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                          chart: {
                              width: 200,
                          },
                          legend: {
                              position: "bottom",
                          },
                      },
                    },
                  ],
              },
          },
          reasonByOperationChart: {
              series: [],
              chartOptions: {
                  chart: {
                      width: '100%',
                      type: "pie",
                  },
                  colors: ['#0d67c9', '#008A5B', '#FF7E6A', '#E69C24', '#7881E3', '#EEE8A9', '#B01A76', '#003371', '#9664C6'],
                  stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    width: 1,
                    dashArray: 0,      
                  },
                  legend: {
                    width: '100%',
                    position: 'right',
                    height: 80,

                  },
                  labels: [],
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                          chart: {
                              width: 200,
                          },
                          legend: {
                              position: "bottom",
                          },
                      },
                    },
                  ],
              },
          },
          social: {
              series: [],
              chartOptions: {
                colors: ['#d6001c', '#22e5db', '#FF7000', '#E69C24', '#7881E3', '#229ED9', '#4267B2', '#C13584', '#0072b1', '#2b2e3c', '#7881E3'],
                  stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 2,
                    dashArray: 0,      
                  },
                  labels: [],
                  chart: {
                      width: 380,
                      type: "pie",
                  },
                  legend: {
                    width: '100%',
                    position: 'bottom'
                  },
                  responsive: [
                      {
                          breakpoint: 480,
                          options: {
                              chart: {
                                  width: 200,
                              },
                              legend: {
                                  position: "bottom",
                              },
                          },
                      },
                  ],
              },
          },
          education: {
              series: [],
              chartOptions: {
                  chart: {
                      width: 380,
                      type: "pie",
                  },
                  labels: [],
                  responsive: [
                      {
                          breakpoint: 480,
                          options: {
                              chart: {
                                  width: '100%',
                                  height: '100%'
                              },
                              legend: {
                                  position: "bottom",
                              },
                          },
                      },
                  ],
              },
          },
          testStaff: {
              count: 0,
              percentage: 0,
          },
          workingStaff: {
              count: 0,
              percentage: 0,
          },
          missingStaff: {
              count: 0,
              percentage: 0,
          },
          firedStaff: {
              count: 0,
              percentage: 0,
          },
          vacancy: 0,
          candidate: 0,
          
          staff_from_candidate: {
              test: {
                  count: 0,
              },
              working: {
                  count: 0,
              },
          },
          staffTodaysBirthdays: [],
          staffExpectedBirthdays: [],
          hasSalaryStaffsStatistic: [],
          requestsForTodayStatistic: [],
          staffPastBirthdays: [],
          telegramStatistic:[],
          tmsList:[],
          mostToiletUsers:[],
          staffWithTotalWork:[],

          tmsChart:{
              chartID: "tmsChart",
              series: [
                  {
                      name: "Въезд",
                      data: [],
                  },
                  {
                      name: "Выезд",
                      data: [],
                  },
              ],
              chartOptions: {
                  colors: ["#e69c24", "#008A5B"],
                  chart: {
                      type: "bar",
                      height: 350,
                  },
                  grid: {
                      show: true,
                      borderColor: '#fff',
                      strokeDashArray: 0,
                      position: 'back'
                  },
                  plotOptions: {
                      bar: {
                          horizontal: false,
                          columnWidth: "55%",
                          endingShape: "rounded",
                          borderRadius: 4,
                      },
                  },
                  dataLabels: {
                      enabled: false,
                      labels: {
                          style: {
                              colors: "#fff",
                              fontSize: "12px",
                          },
                      },
                  },
                  stroke: {
                      show: true,
                      width: 2,
                      colors: ["transparent"],
                  },
                  xaxis: {
                      categories: [],
                      labels: {
                          style: {
                              colors: "#000",
                              fontSize: "12px",
                          },
                      },
                  },
                  yaxis: {
                      title: {
                          text: "",
                      },
                      labels: {
                          style: {
                              colors: "#000",
                              fontSize: "12px",
                          },
                      },
                  },
                  fill: {
                      opacity: 1,
                  },
                  tooltip: {
                      y: {
                          formatter: function (val) {
                              return " " + val + "";
                          },
                      },
                  },
              },
          }
    
  }),
  watch: {
      "form.branch_id": {
          handler: async function (newVal, oldVal) {
              if (newVal != oldVal) {
                  this.fetchAllData();
              }
          },
          deep: true,
          immediate: true,
      }

  },
  async created() {
      this.fetchAllData()
      this.chartColor()
      
      // this.fetchStatistics();

      this.windowWidth = screen.width
      if (screen.width >= 1600) {
          this.resposeChart()
          this.fixedElem = 10
      } else if (screen.width <=600) {
          this.mobileResponse()
      } else {
          this.fixedElem = 7
      }
  },
  computed: {
      ...mapGetters({
          mode: "MODE",
          statistics: "statistics/statistics",
          newcomestaffs: "statistics/newcomestaffs",
          completestaffprofile: "statistics/completestaffprofile",
          staffagestatistic: "statistics/staffagestatistic",
          staffupdate: "statistics/staffupdate",
          genderstatistic: "statistics/genderstatistic",
          todaybirthdays: "statistics/todaybirthdays",
          expectedbirthdays: "statistics/expectedbirthdays",
          pastbirthdays: "statistics/pastbirthdays",
          staffbyeducation: "statistics/staffbyeducation",
          staffbyreason: "statistics/staffbyreason",
          staffworktype: "statistics/staffworktype",
          workexperience: "statistics/workexperience",
          completeprofilethefiliale: "statistics/completeprofilethefiliale",
          employessbyregion: "statistics/employessbyregion",
          stafffromcandidate: "statistics/stafffromcandidate",
          candidate: "statistics/candidate",
          hasSalayStaffs: "statistics/hasSalayStaffs",
          requestsForToday: "statistics/requestsForToday",
          visitors: "statistics/visitors",
          releaseandreception: "statistics/releaseandreception",
          source: "statistics/source",
          wages: "statistics/wages",
          averagesalarybybranches: "statistics/averagesalarybybranches",
          staffattendences: "statistics/staffattendences",
          faceidregistration: "statistics/faceidregistration",
          attendstaffweek: "statistics/attendstaffweek",
          stafftelegraminformation: "statistics/stafftelegraminformation",
          topmanagers: "statistics/topmanagers",
          toptoiletattendants: "statistics/toptoiletattendants",
          topstaffwithtotalwork: "statistics/topstaffwithtotalwork",
          employeekpp: "statistics/employeekpp",
          transportkpp: "statistics/transportkpp",
          reasonbyoperation: "statistics/reasonbyoperation",
          tmsstatistic: "statistics/tmsstatistic",
          tmslist: "statistics/tmslist",
          staffbymood: "statistics/staffbymood",
          situationfortoday: "statistics/situationfortoday"
      }),
  },
  methods: {
      ...mapActions({
          getStatistics: "statistics/getStatistics",
          getNewComeStaffs: "statistics/getNewComeStaffs",
          getCompleteStaffProfile: "statistics/getCompleteStaffProfile",
          getStaffAgeStatistic: "statistics/getStaffAgeStatistic",
          getStaffUpdate: "statistics/getStaffUpdate",
          getStaffGenderStatistic: "statistics/getStaffGenderStatistic",
          getTodayBirthdays: "statistics/getTodayBirthdays",
          getExpectedBirthdays: "statistics/getExpectedBirthdays",
          getPastBirthdays: "statistics/getPastBirthdays",
          getStaffByEducation: "statistics/getStaffByEducation",
          getStaffByReason: "statistics/getStaffByReason",
          getStaffWorkType: "statistics/getStaffWorkType",
          getWorkExperience: "statistics/getWorkExperience",
          getCompleteProfileTheFiliale: "statistics/getCompleteProfileTheFiliale",
          getEmployeesByRegion: "statistics/getEmployeesByRegion",
          getStaffFromCandidate: "statistics/getStaffFromCandidate",
          getCandidate: "statistics/getCandidate",
          getHasSalaryStaffs: "statistics/getHasSalaryStaffs",
          getRequestsForToday: "statistics/getRequestsForToday",
          getStaffByMood: "statistics/getStaffByMood",
          getSituationForToday: "statistics/getSituationForToday",
          getReleaseAndReception: "statistics/getReleaseAndReception",
          getVisitors: "statistics/getVisitors",
          getSource: "statistics/getSource",
          getWages: "statistics/getWages",
          getAverageSalaryByBranches: "statistics/getAverageSalaryByBranches",
          getStaffAttendences: "statistics/getStaffAttendences",
          getFaceIdRegistration: "statistics/getFaceIdRegistration",
          getAttendStaffWeek: "statistics/getAttendStaffWeek",
          getSaffTelegramInformation: "statistics/getSaffTelegramInformation",
          getTopToiletAttendants: "statistics/getTopToiletAttendants",
          getStaffWithTotalWork: "statistics/getStaffWithTotalWork",
          getTopManagers: "statistics/getTopManagers",
          getEmployeeKpp: "statistics/getEmployeeKpp",
          getTransportKpp: "statistics/getTransportKpp",
          getReasonByOperation: "statistics/getReasonByOperation",
          getTmsStatistic: "statistics/getTmsStatistic",
          getTmsList: "statistics/getTmsList",

      }),
      generateTime(val){
        if ((val - parseInt(val))) {
          return parseInt(val) + i18n.t('message.hour') +' '+ ((val - parseInt(val)) *60).toFixed(0)+i18n.t('message.minut');
        }
        else{
          return  parseInt(val) + i18n.t('message.hour');
        }
      },
      testlabel () {
        this.districtByStaff
      },
      sortedMethod (e) {
        this.sortedNumber = e
        this.form.sortedNumber = e
        this.fetchAllData()
      },
      chartColor () {
        if (this.mode) {
          this.apexChartBar.chartOptions.xaxis.labels.style.colors = '#323248'
          this.visitorsChart.chartOptions.xaxis.labels.style.colors = '#323248'
          this.apexChartBar.chartOptions.yaxis.labels.style.colors = '#323248'
          this.employeeKpps.chartOptions.xaxis.labels.style.colors = '#323248'
          this.employeeKpps.chartOptions.grid.borderColor = '#92929f'
          this.employeeKpps.chartOptions.yaxis.labels.style.colors = '#323248'
          this.tmsChart.chartOptions.xaxis.labels.style.colors = '#323248'
          this.tmsChart.chartOptions.grid.borderColor = '#92929f'
          this.tmsChart.chartOptions.yaxis.labels.style.colors = '#323248'
          this.visitorsChart.chartOptions.yaxis.labels.style.colors = '#323248'
          this.apexChartBar.chartOptions.grid.borderColor = '#92929f'
          this.visitorsChart.chartOptions.grid.borderColor = '#92929f'
          this.compleinProfile.chartOptions.xaxis.labels.style.colors = '#323248'
          this.compleinProfile.chartOptions.yaxis.labels.style.colors = '#323248'
          this.compleinProfile.chartOptions.grid.borderColor = '#92929f'
          this.worksPlace.chartOptions.xaxis.labels.style.colors = '#323248'
          this.worksPlace.chartOptions.yaxis.labels.style.colors = '#323248'
          this.worksPlace.chartOptions.grid.borderColor = '#92929f'
          this.districtByStaff.chartOptions.xaxis.labels.style.colors = '#323248'
          this.districtByStaff.chartOptions.yaxis.labels.style.colors = '#323248'
          this.districtByStaff.chartOptions.grid.borderColor = '#92929f'
          this.apexChartSalary.chartOptions.xaxis.labels.style.colors = '#323248'
          this.apexChartSalary.chartOptions.yaxis.labels.style.colors = '#323248'
          this.apexChartSalary.chartOptions.grid.borderColor = '#92929f'
          this.filial.chartOptions.plotOptions.pie.donut.labels.total.color = '#92929f'
          this.filial.chartOptions.plotOptions.pie.donut.labels.value.color = '#323248'
          this.gender.chartOptions.legend.labels.colors = '#92929f'
          this.reason.chartOptions.legend.labels.colors = '#92929f'
          this.types.chartOptions.legend.labels.colors = '#92929f'
        } else {
          this.apexChartBar.chartOptions.xaxis.labels.style.colors = '#fff'
          this.apexChartBar.chartOptions.yaxis.labels.style.colors = '#fff'
          this.apexChartBar.chartOptions.grid.borderColor = '#fff'
          this.employeeKpps.chartOptions.xaxis.labels.style.colors = '#fff'
          this.employeeKpps.chartOptions.yaxis.labels.style.colors = '#fff'
          this.employeeKpps.chartOptions.grid.borderColor = '#fff'
          this.tmsChart.chartOptions.xaxis.labels.style.colors = '#fff'
          this.tmsChart.chartOptions.yaxis.labels.style.colors = '#fff'
          this.tmsChart.chartOptions.grid.borderColor = '#fff'
          this.visitorsChart.chartOptions.xaxis.labels.style.colors = '#fff'
          this.visitorsChart.chartOptions.yaxis.labels.style.colors = '#fff'
          this.visitorsChart.chartOptions.grid.borderColor = '#fff'
          this.compleinProfile.chartOptions.xaxis.labels.style.colors = '#fff'
          this.compleinProfile.chartOptions.yaxis.labels.style.colors = '#fff'
          this.compleinProfile.chartOptions.grid.borderColor = '#fff'
          this.worksPlace.chartOptions.xaxis.labels.style.colors = '#fff'
          this.worksPlace.chartOptions.yaxis.labels.style.colors = '#fff'
          this.worksPlace.chartOptions.grid.borderColor = '#fff'
          this.districtByStaff.chartOptions.xaxis.labels.style.colors = '#fff'
          this.districtByStaff.chartOptions.yaxis.labels.style.colors = '#fff'
          this.districtByStaff.chartOptions.grid.borderColor = '#fff'
          this.apexChartSalary.chartOptions.xaxis.labels.style.colors = '#fff'
          this.apexChartSalary.chartOptions.yaxis.labels.style.colors = '#fff'
          this.apexChartSalary.chartOptions.grid.borderColor = '#fff'
          this.filial.chartOptions.plotOptions.pie.donut.labels.total.color = '#fff'
          this.filial.chartOptions.plotOptions.pie.donut.labels.value.color = '#fff'
          this.gender.chartOptions.legend.labels.colors = '#fff'
          this.reason.chartOptions.legend.labels.colors = '#fff'
          this.types.chartOptions.legend.labels.colors = '#fff'


        }
      },
      resposeChart () {
          this.filial.chartOptions.legend.width = 200
          this.filial.chartOptions.legend.position = 'right'
          this.reason.chartOptions.legend.width = 200
          this.reason.chartOptions.legend.position = 'right'
          this.gender.chartOptions.legend.width = 200
          this.gender.chartOptions.legend.position = 'right'
          this.types.chartOptions.legend.width = '100%'
          this.types.chartOptions.legend.position = 'bottom'
          this.social.chartOptions.legend.width = 150
          this.social.chartOptions.legend.position = 'right'
      },
      mobileResponse() {
        console.log("Mobile work");
        
      },
      async fetchAllData () {
         
        if (this.form.sortedNumber == 5) {
          await this.getStaffAttendences(this.form)
          await this.getFaceIdRegistration(this.form)
          
          await this.getAttendStaffWeek(this.form)            
            .then((res) => {
            })
          await this.getTopToiletAttendants(this.form)
          .then((res) => {
            // this.topToiletAttendantsFunction()
              this.mostToiletUsers = this.toptoiletattendants.most_go_to_toilet
          })
         
          await this.getSaffTelegramInformation(this.form)
            .then((res) => {
            }) 
        }
        else if (this.form.sortedNumber == 1) {
          await this.getSituationForToday(this.form)
            .then((res) => {
          })
          await this.getRequestsForToday(this.form)
            .then((res) => {
              this.requestsForTodayStatistic = this.requestsForToday;
          })
          await this.getStaffByMood(this.form)
            .then((res) => {
          })
          await this.getNewComeStaffs(this.form)

          await this.getCompleteStaffProfile(this.form)
            .then((res) => {this.ageFunction()})
          await this.getStaffUpdate(this.form)
            .then((res) => {
              this.getCandidate(this.form)
                .then((res) => {
                  this.getStaffFromCandidate(this.form)
                    .then((res) => {
                      this.changeStatistics();
                    })
                })
            })
          await this.getStaffAgeStatistic(this.form)
            .then((res) => {
              this.ageFunction() 
            })
          await this.getReleaseAndReception(this.form)
            .then((res) => {
              this.apexChartBarFunction()
              this.changeStatistics()
            })
         
          await this.getPastBirthdays(this.form)
            .then((res) => {
              this.staffPastBirthdays = this.pastbirthdays.past_birthdays
            }) 
          await this.getTodayBirthdays(this.form)
            .then((res) => {
              this.staffTodaysBirthdays = this.todaybirthdays.today_birthday
            })
          await this.getExpectedBirthdays(this.form)
            .then((res) => {
              this.staffExpectedBirthdays = this.expectedbirthdays.expected_birthdays
            }) 
            await this.getStaffWithTotalWork(this.form)
          .then((res) => {
            // this.topToiletAttendantsFunction()
              this.staffWithTotalWork = this.topstaffwithtotalwork?this.topstaffwithtotalwork.the_best_staffs:null
          })
          await this.getStaffGenderStatistic(this.form)
            .then((res) => {
              this.genderFunction();
            })
          await this.getStaffByEducation(this.form)
            .then((res) => {
              this.educationFunction()
            })
          
          await this.getStaffByReason(this.form)
            .then((res) => {
              this.reasonFunction()
            })
          await this.getStaffWorkType(this.form)
            .then((res) => {
              this.typeFunction()
            })
        
          
          
          await this.getWorkExperience(this.form)
            .then((res) => {
              this.workChartPlace()
            })
          await this.getCompleteProfileTheFiliale(this.form)
            .then((res) => {
              this.complaintProfileFunc()
            })
          await this.getEmployeesByRegion(this.form)
            .then((res) => {
              this.districtFunc()
            })
          await this.getEmployeesByRegion(this.form)
          .then((res) => {
            this.districtFunc()
          })             
        }
        else if (this.form.sortedNumber == 2) {
          
          await this.getStaffUpdate(this.form)
            .then((res) => {
              this.getCandidate(this.form)
                .then((res) => {
                  this.getStaffFromCandidate(this.form)
                    .then((res) => {
                      this.changeStatistics();
                    })
                })
            })
          await this.getSource(this.form)
            .then((res) => {
              this.socialFunction();
            }) 
        }
        else if (this.form.sortedNumber == 3) {
          await this.getWages(this.form)
            .then((res) => {
              this.apexChartSalaryFunction()
            })
            await this.getHasSalaryStaffs(this.form)
            .then((res) => {
              this.hasSalaryStaffsStatistic = this.hasSalayStaffs.hasSalaryStatistic;
            })
          
          await this.getAverageSalaryByBranches(this.form)
            .then((res) => {
              this.filialFunction()
            })
             await this.getVisitors(this.form)
            .then((res) => {
              this.apexChartBarVisitorsFunction()
              this.changeStatistics()
            })
          await this.changeStatistics()
        }
        else if (this.form.sortedNumber == 4) {
           
          await this.getVisitors(this.form)
          .then((res) => {
            this.apexChartBarVisitorsFunction()
            // this.changeStatistics()
          })

            await this.getTopManagers(this.form)
            .then((res) => {
              this.topManagersFunction()
            })
            await this.getEmployeeKpp(this.form)
              .then((res) => {
                this.employeeKppFunction();
            })
            await this.getTransportKpp(this.form)
            .then((res) => {
              this.transportKppFunction()
            })
            await this.getReasonByOperation(this.form)
            .then((res) => {
              this.reasonByOperationFunction()
            })
             await this.getTmsStatistic(this.form)
            .then((res) => {
              this.tmsFunction()
            })
             await this.getTmsList(this.form)
            .then((res) => {
              this.tmsList = this.tmslist.tms_list
            }) 
            
            this.changeStatistics()
            
          // await this.changeStatistics()
        }
      },
      handleClick(type) {
          this.activeType = type;
          if (this.genderstatistic) {
              this.changeStatistics();
          }
      },
      changeStatistics() {
          switch (this.activeType) {
              case "daily":
                  if(this.form.sortedNumber == 1){
                    this.requestsForTodayStatistic = this.requestsForToday;
                      this.testStaff.count =
                          this.staffupdate.staff_update.staff.test.count;
                      this.testStaff.percentage =
                          this.staffupdate.staff_update.staff.test.percentage;
                      this.workingStaff.count =
                          this.staffupdate.staff_update.staff.current.count;
                      this.workingStaff.percentage =
                          this.staffupdate.staff_update.staff.current.percentage;
                      this.missingStaff.count =
                          this.staffupdate.staff_update.staff.missing_staff.count;
                      this.missingStaff.percentage =
                          this.staffupdate.staff_update.staff.missing_staff.percentage;
                      this.firedStaff.count =
                          this.staffupdate.staff_update.staff.fired.count;
                      this.firedStaff.percentage =
                          this.staffupdate.staff_update.staff.fired.percentage;
                      this.apexChartBarFunction("daily");
                     
                      this.complaintProfileFunc();
                      this.workChartPlace();
                  }
                  else if(this.form.sortedNumber == 2){
                      this.vacancy = this.candidate.candidate.daily;
                      this.candidate = this.candidate.candidate.daily;
                      this.staff_from_candidate.test.count =
                          this.stafffromcandidate.staff_from_candidate.test.daily;
                      this.staff_from_candidate.working.count =
                          this.stafffromcandidate.staff_from_candidate.working.daily;
                  }
                  else if(this.form.sortedNumber == 3){
                      this.apexChartSalaryFunction("daily");
                      this.hasSalaryStaffsStatistic = this.hasSalayStaffs.hasSalaryStatistic;
                      this.filialFunction();
                  }
                   else if(this.form.sortedNumber == 5){
                    //  this.topToiletAttendantsFunction();
                     this.telegramStatistic =  this.candidate.candidate.telegramStaff;

                  }
                  else if(this.form.sortedNumber == 4){
                    this.apexChartBarVisitorsFunction("daily");
                      this.topManagersFunction("daily");
                      this.employeeKppFunction("daily");
                       this.transportKppFunction('daily');
                       this.reasonByOperationFunction('daily');
                       this.tmsFunction('daily');
                       

                  }
                  // this.telegramStaffFunction();

                  break;
              case "weekly":
                   if(this.form.sortedNumber == 1){
                    this.requestsForTodayStatistic = this.requestsForToday;
                     this.testStaff.count = this.staffupdate.staff_update.staff.test.count;
                      this.testStaff.percentage = this.staffupdate.staff_update.staff.test.percentage;
                      this.workingStaff.count = this.staffupdate.staff_update.staff.current.count;
                      this.workingStaff.percentage =
                          this.staffupdate.staff_update.staff.current.percentage;
                      this.missingStaff.count =
                          this.staffupdate.staff_update.staff.missing_staff.count;
                      this.missingStaff.percentage =
                          this.staffupdate.staff_update.staff.missing_staff.percentage;
                      this.firedStaff.count =
                          this.staffupdate.staff_update.staff.fired.count;
                      this.firedStaff.percentage =
                      this.staffupdate.staff_update.staff.fired.percentage;
                      this.apexChartBarFunction("weekly");
                      this.complaintProfileFunc();
                      this.workChartPlace();
                      }
                      else if(this.form.sortedNumber == 2){
                          this.vacancy = this.candidate.candidate.weekly;
                          this.candidate = this.candidate.candidate.weekly;
                          this.staff_from_candidate.test.count =
                              this.stafffromcandidate.staff_from_candidate.test.weekly;
                          this.staff_from_candidate.working.count =
                              this.stafffromcandidate.staff_from_candidate.working.weekly;
                      }
                      else if(this.form.sortedNumber == 3){
                          this.apexChartSalaryFunction("weekly");
                          this.hasSalaryStaffsStatistic = this.hasSalayStaffs.hasSalaryStatistic;
                          this.filialFunction();

                      }
                       else if(this.form.sortedNumber == 4){
                      this.apexChartBarVisitorsFunction("weekly");
                      this.topManagersFunction("weekly");
                      this.employeeKppFunction("weekly");
                       this.transportKppFunction('weekly');
                       this.reasonByOperationFunction('weekly');
                       this.tmsFunction('weekly');

                      }
                      else if(this.form.sortedNumber == 5){
                        // this.topToiletAttendantsFunction();
                          this.telegramStatistic = this.candidate.candidate.telegramStaff;
                      }


                  break;
              case "monthly":
                   if(this.form.sortedNumber == 1){
                    this.requestsForTodayStatistic = this.requestsForToday;
                     this.testStaff.count =
                      this.staffupdate.staff_update.staff.test.count;
                      this.testStaff.percentage =
                          this.staffupdate.staff_update.staff.test.percentage;
                      this.workingStaff.count =
                          this.staffupdate.staff_update.staff.current.count;
                      this.workingStaff.percentage =
                          this.staffupdate.staff_update.staff.current.percentage;
                      this.missingStaff.count =
                          this.staffupdate.staff_update.staff.missing_staff.count;
                      this.missingStaff.percentage =
                          this.staffupdate.staff_update.staff.missing_staff.percentage;
                      this.firedStaff.count =
                          this.staffupdate.staff_update.staff.fired.count;
                      this.firedStaff.percentage =
                      this.staffupdate.staff_update.staff.fired.percentage;
                       this.apexChartBarFunction("monthly");
                      this.complaintProfileFunc();
                      this.workChartPlace();
                      }
                      else if(this.form.sortedNumber == 2){
                          this.vacancy = this.candidate.candidate.monthly;
                          this.candidate = this.candidate.candidate.monthly;
                          this.staff_from_candidate.test.count =
                              this.stafffromcandidate.staff_from_candidate.test.monthly;
                          this.staff_from_candidate.working.count =
                              this.stafffromcandidate.staff_from_candidate.working.monthly;
                      }
                      else if(this.form.sortedNumber == 3){
                         this.apexChartSalaryFunction("monthly");
                         this.hasSalaryStaffsStatistic = this.hasSalayStaffs.hasSalaryStatistic;
                          this.filialFunction();

                      }
                      else if(this.form.sortedNumber == 4){
                        this.apexChartBarVisitorsFunction("monthly");
                      this.topManagersFunction("monthly");
                      this.employeeKppFunction("monthly");
                       this.transportKppFunction('monthly');
                       this.reasonByOperationFunction('monthly');
                        this.tmsFunction('monthly');
                      }
                      else if(this.form.sortedNumber == 5){
                        // this.topToiletAttendantsFunction();
                          this.telegramStatistic =  this.candidate.candidate.telegramStaff;

                      }

                  break;
              case "yearly":
                  if(this.form.sortedNumber == 1){
                    this.requestsForTodayStatistic = this.requestsForToday;
                      this.testStaff.count =
                      this.staffupdate.staff_update.staff.test.count;
                      this.testStaff.percentage =
                          this.staffupdate.staff_update.staff.test.percentage;
                      this.workingStaff.count =
                          this.staffupdate.staff_update.staff.current.count;
                      this.workingStaff.percentage =
                          this.staffupdate.staff_update.staff.current.percentage;
                      this.missingStaff.count =
                          this.staffupdate.staff_update.staff.missing_staff.count;
                      this.missingStaff.percentage =
                          this.staffupdate.staff_update.staff.missing_staff.percentage;
                      this.firedStaff.count =
                          this.staffupdate.staff_update.staff.fired.count;
                      this.firedStaff.percentage =
                      this.staffupdate.staff_update.staff.fired.percentage;
                       this.apexChartBarFunction("yearly");

                      this.complaintProfileFunc();
                      this.workChartPlace();
                      }
                      else if(this.form.sortedNumber == 2){
                         this.vacancy = this.candidate.candidate.yearly;
                          this.candidate = this.candidate.candidate.yearly;
                          this.staff_from_candidate.test.count =
                              this.stafffromcandidate.staff_from_candidate.test.yearly;
                          this.staff_from_candidate.working.count =
                              this.stafffromcandidate.staff_from_candidate.working.yearly;
                      }
                      else if(this.form.sortedNumber == 3){
                         this.apexChartSalaryFunction("yearly");
                         this.hasSalaryStaffsStatistic = this.hasSalayStaffs.hasSalaryStatistic;
                          this.filialFunction();
                      }
                       else if(this.form.sortedNumber == 4){
                        this.apexChartBarVisitorsFunction("yearly");
                        this.topManagersFunction("yearly");
                        this.employeeKppFunction("yearly");
                        this.transportKppFunction('yearly');
                        this.reasonByOperationFunction('yearly');
                        this.tmsFunction('yearly');
                      }
                      else if(this.form.sortedNumber == 5){
                        // this.topToiletAttendantsFunction();                          
                          this.telegramStatistic =  this.candidate.candidate.telegramStaff;
                      }
                  break;
          }
      },
      districtFunc() {
              this.cleardistrictChart();
              for (const key in this.employessbyregion.district_staff) {
                if (
                  this.employessbyregion.district_staff.hasOwnProperty(
                    key
                      )
                  ) {
                      this.districtByStaff.series[0].data.push(
                        this.employessbyregion.district_staff[key].status[0]
                      );
                      
                      this.districtByStaff.series[1].data.push(
                        this.employessbyregion.district_staff[key].status[1]
                      );
                      this.districtByStaff.series[2].data.push(
                        this.employessbyregion.district_staff[key].status[2]
                      );
                      this.districtByStaff.chartOptions.xaxis.categories.push(
                        this.employessbyregion.district_staff[key].name
                      );                      
                  }
              }
              
          this.$refs["districtByStaff"].updateOptions(this.districtByStaff);
          this.adaptiveSize = this.employessbyregion.district_staff.length * 45 
      },
      apexChartBarFunction(type) {
          if (type == "weekly" || type == "daily") {
              this.clearApexChar();
              for (const key in this.releaseandreception.bar_chart.fired.weekly) {
                  if (
                      this.releaseandreception.bar_chart.fired.weekly.hasOwnProperty(
                          key
                      )
                  ) {
                      this.apexChartBar.series[0].data.push(
                          this.releaseandreception.bar_chart.test.weekly[key].count
                      );
                      this.apexChartBar.series[1].data.push(
                          this.releaseandreception.bar_chart.working.weekly[key].count
                      );
                      this.apexChartBar.series[2].data.push(
                          this.releaseandreception.bar_chart.fired.weekly[key].count
                      );
                      this.apexChartBar.chartOptions.xaxis.categories.push(
                          this.releaseandreception.bar_chart.fired.weekly[key].date
                      );
                  }
              }
          }
          if (type == "monthly") {
              this.clearApexChar();
              for (const key in this.releaseandreception.bar_chart.fired.monthly) {
                  if (
                      this.releaseandreception.bar_chart.fired.monthly.hasOwnProperty(
                          key
                      )
                  ) {
                      this.apexChartBar.series[0].data.push(
                          this.releaseandreception.bar_chart.test.monthly[key].count
                      );
                      this.apexChartBar.series[1].data.push(
                          this.releaseandreception.bar_chart.working.monthly[key].count
                      );
                      this.apexChartBar.series[2].data.push(
                          this.releaseandreception.bar_chart.fired.monthly[key].count
                      );
                      this.apexChartBar.chartOptions.xaxis.categories.push(
                          this.releaseandreception.bar_chart.fired.monthly[key].date
                      );
                  }
              }
          }
          if (type == "yearly") {
              this.clearApexChar();
              for (const key in this.releaseandreception.bar_chart.fired.yearly) {
                if ( this.releaseandreception.bar_chart.fired.yearly[key]) {
                      this.apexChartBar.series[0].data.push(
                          this.releaseandreception.bar_chart.test.yearly[key].count
                      );
                      this.apexChartBar.series[1].data.push(
                          this.releaseandreception.bar_chart.working.yearly[key].count
                      );
                      this.apexChartBar.series[2].data.push(
                          this.releaseandreception.bar_chart.fired.yearly[key].count
                      );
                      this.apexChartBar.chartOptions.xaxis.categories.push(
                          this.releaseandreception.bar_chart.fired.yearly[key].month
                      );
                  }
              }
          }
          this.$refs["apexChartBar"].updateOptions(this.apexChartBar);
      },
      employeeKppFunction(type) {
          if (type == "weekly" || type == "daily") {
              this.clearEmployeeKpp();
              for (const key in this.employeekpp.employee_kpp.expected_employee.weekly) {
                  if (
                      this.employeekpp.employee_kpp.expected_employee.weekly.hasOwnProperty(
                          key
                      )
                  ) {
                      this.employeeKpps.series[0].data.push(
                          this.employeekpp.employee_kpp.expected_employee.weekly[key].count
                      );
                      this.employeeKpps.series[1].data.push(
                          this.employeekpp.employee_kpp.factual_employee.weekly[key].count
                      );
                      this.employeeKpps.chartOptions.xaxis.categories.push(
                          this.employeekpp.employee_kpp.factual_employee.weekly[key].date
                      );
                  }
              }
          }
          if (type == "monthly") {
              this.clearEmployeeKpp();
              for (const key in this.employeekpp.employee_kpp.expected_employee.monthly) {
                  if (
                      this.employeekpp.employee_kpp.expected_employee.monthly.hasOwnProperty(
                          key
                      )
                  ) {
                      this.employeeKpps.series[0].data.push(
                          this.employeekpp.employee_kpp.expected_employee.monthly[key].count
                      );
                      this.employeeKpps.series[1].data.push(
                          this.employeekpp.employee_kpp.factual_employee.monthly[key].count
                      );
                      this.employeeKpps.chartOptions.xaxis.categories.push(
                          this.employeekpp.employee_kpp.factual_employee.monthly[key].date
                      );
                  }
              }
          }
          if (type == "yearly") {
              this.clearEmployeeKpp();
              for (const key in this.employeekpp.employee_kpp.expected_employee.yearly) {
                if ( this.employeekpp.employee_kpp.expected_employee.yearly[key]) {                    
                      this.employeeKpps.series[0].data.push(
                          this.employeekpp.employee_kpp.expected_employee.yearly[key].count
                      );
                      this.employeeKpps.series[1].data.push(
                          this.employeekpp.employee_kpp.factual_employee.yearly[key].count
                      );
                      this.employeeKpps.chartOptions.xaxis.categories.push(
                          this.employeekpp.employee_kpp.factual_employee.yearly[key].month
                      );
                  }
              }
          }
          this.$refs["employeeKpps"].updateOptions(this.employeeKpps);
      },
      tmsFunction(type) {
          if (type == "weekly" || type == "daily") {
              this.clearTmsFunction();
              for (const key in this.tmsstatistic.tms.incoming.weekly) {
                  if (
                      this.tmsstatistic.tms.incoming.weekly.hasOwnProperty(
                          key
                      )
                  ) {
                      this.tmsChart.series[0].data.push(
                          this.tmsstatistic.tms.incoming.weekly[key].count
                      );
                      this.tmsChart.series[1].data.push(
                          this.tmsstatistic.tms.outgoing.weekly[key].count
                      );
                      this.tmsChart.chartOptions.xaxis.categories.push(
                          this.tmsstatistic.tms.outgoing.weekly[key].date
                      );
                  }
              }
          }
          if (type == "monthly") {
              this.clearTmsFunction();
              for (const key in this.tmsstatistic.tms.incoming.monthly) {
                  if (
                      this.tmsstatistic.tms.incoming.monthly.hasOwnProperty(
                          key
                      )
                  ) {
                      this.tmsChart.series[0].data.push(
                          this.tmsstatistic.tms.incoming.monthly[key].count
                      );
                      this.tmsChart.series[1].data.push(
                          this.tmsstatistic.tms.outgoing.monthly[key].count
                      );
                      this.tmsChart.chartOptions.xaxis.categories.push(
                          this.tmsstatistic.tms.outgoing.monthly[key].date
                      );
                  }
              }
          }
          if (type == "yearly") {
              this.clearTmsFunction();
              for (const key in this.tmsstatistic.tms.incoming.yearly) {
                if ( this.tmsstatistic.tms.incoming.yearly[key]) {                    
                      this.tmsChart.series[0].data.push(
                          this.tmsstatistic.tms.incoming.yearly[key].count
                      );
                      this.tmsChart.series[1].data.push(
                          this.tmsstatistic.tms.outgoing.yearly[key].count
                      );
                      this.tmsChart.chartOptions.xaxis.categories.push(
                          this.tmsstatistic.tms.outgoing.yearly[key].month
                      );
                  }
              }
          }
          this.$refs["tmsChart"].updateOptions(this.tmsChart);
      },
      apexChartBarVisitorsFunction(type) {
          if (type == "weekly" || type == "daily") {
              this.clearApexCharVisitors();
              for (const key in this.visitors.visitors.weekly) {
                  if (
                      this.visitors.visitors.weekly.hasOwnProperty(
                          key
                      )
                  ) {
                      this.visitorsChart.series[0].data.push(
                          this.visitors.visitors.weekly[key].count
                      );
                    
                    
                      this.visitorsChart.chartOptions.xaxis.categories.push(
                          this.visitors.visitors.weekly[key].date
                      );
                  }
              }
          }
          if (type == "monthly") {
              this.clearApexCharVisitors();
              for (const key in this.visitors.visitors.monthly) {
                  if (
                      this.visitors.visitors.monthly.hasOwnProperty(
                          key
                      )
                  ) {
                      this.visitorsChart.series[0].data.push(
                          this.visitors.visitors.monthly[key].count
                      );
                      this.visitorsChart.chartOptions.xaxis.categories.push(
                          this.visitors.visitors.monthly[key].date
                      );
                  }
              }
          }
          if (type == "yearly") {
              this.clearApexCharVisitors();
              for (const key in this.visitors.visitors.yearly) {
                if (this.visitors.visitors.yearly[key]) {
                      this.visitorsChart.series[0].data.push(
                          this.visitors.visitors.yearly[key].count
                      );
                      this.visitorsChart.chartOptions.xaxis.categories.push(
                          this.visitors.visitors.yearly[key].month
                      );
                  }
              }
          }
          this.$refs["visitorsChart"].updateOptions(this.visitorsChart);
      },
      workChartPlace() {
              this.clearWorkPlaceChart();
              for (const key in this.workexperience.work_place) {
                if (
                  this.workexperience.work_place.hasOwnProperty(
                    key
                      )
                  ) {
                      this.worksPlace.series[0].data.push(
                        this.workexperience.work_place[key].count
                      );
                      this.worksPlace.chartOptions.xaxis.categories.push(
                         this.workexperience.work_place[key].date
                      );
                      
                  }
              }
          this.$refs["worksPlace"].updateOptions(this.worksPlace);
      },
      complaintProfileFunc() {
              this.clearcomplaintProfileChart();

              for (const key in this.completeprofilethefiliale.compleint) {
                if (
                  this.completeprofilethefiliale.compleint.hasOwnProperty(
                    key
                      )
                  ) {
                      this.compleinProfile.series[0].data.push(
                        this.completeprofilethefiliale.compleint[key].procent
                      );
                      this.compleinProfile.chartOptions.xaxis.categories.push(
                         this.completeprofilethefiliale.compleint[key].name
                      );
                      
                  }
              }


          this.$refs["compleinProfile"].updateOptions(this.compleinProfile);
      },
      apexChartSalaryFunction(type) {
         if (type == "weekly" || type == "daily") {
              this.clearApexChartSalary();
              for (const key in this.wages.salaryTran.transaction.weekly) {
                    if (
                      this.wages.salaryTran.transaction.weekly.hasOwnProperty(
                        key
                      )
                  ) {
                      this.apexChartSalary.series[0].data.push(
                          this.wages.salaryTran.salary.weekly[key].summa
                      );                  
                      this.apexChartSalary.series[1].data.push(
                          this.wages.salaryTran.transaction.weekly[key].summa
                      );
                      this.apexChartSalary.chartOptions.xaxis.categories.push(
                          this.wages.salaryTran.transaction.weekly[key].date
                      );
                  }
              }
          }
          if (type == "monthly") {
              this.clearApexChartSalary();
              for (const key in this.wages.salaryTran.transaction.monthly) {
                  if (
                      this.wages.salaryTran.transaction.monthly.hasOwnProperty(
                          key
                      )
                  ) {
                      this.apexChartSalary.series[0].data.push(
                          this.wages.salaryTran.salary.monthly[key].summa
                      );
                       this.apexChartSalary.series[1].data.push(
                          this.wages.salaryTran.transaction.monthly[key].summa
                      );
                      this.apexChartSalary.chartOptions.xaxis.categories.push(
                          this.wages.salaryTran.transaction.monthly[key].date
                      );
                  }
              }

          }
          if (type == "yearly") {
              this.clearApexChartSalary();
              for (const key in this.wages.salaryTran.salary.yearly) {
                  if (
                      this.wages.salaryTran.salary.yearly.hasOwnProperty(
                          key
                      )
                  ) {
                      this.apexChartSalary.series[0].data.push(
                          this.wages.salaryTran.salary.yearly[key].summa
                      );
                       this.apexChartSalary.series[1].data.push(
                          this.wages.salaryTran.transaction.yearly[key].summa
                      );
                      this.apexChartSalary.chartOptions.xaxis.categories.push(
                          this.wages.salaryTran.transaction.yearly[key].month
                      );
                  }
              }
          }

              console.log('mounthly', this.apexChartSalary);

          this.$refs["apexChartSalary"].updateOptions(this.apexChartSalary);
      },
      ageFunction() {
          this.age.chartOptions.labels.splice(
              0,
              this.age.chartOptions.labels.length
          );
          this.age.series.splice(0, this.age.series.length);
          for (const key in this.staffagestatistic.age) {
              if (this.staffagestatistic.age.hasOwnProperty(key)) {
                  this.age.chartOptions.labels.push(key);
                  this.age.series.push(this.staffagestatistic.age[key].count);
              }
          }
      },
      reasonFunction() {
          this.reason.chartOptions.labels.splice(
              0,
              this.reason.chartOptions.labels.length
          );
          this.reason.series.splice(0, this.reason.series.length);
          for (const key in this.staffbyreason.reason) {
              if (this.staffbyreason.reason.hasOwnProperty(key)) {
                  this.reason.chartOptions.labels.push(key);
                  this.reason.series.push(this.staffbyreason.reason[key].count);
              }
          }
      },
      topManagersFunction(type) {
        if (type == "weekly" || type == "daily") {
               this.topManagers.chartOptions.labels.splice(
              0,
              this.topManagers.chartOptions.labels.length
              );
              this.topManagers.series.splice(0, this.topManagers.series.length);
              for (const key in this.topmanagers.top_managers.weekly) {
                  if (this.topmanagers.top_managers.weekly.hasOwnProperty(key)) {
                      this.topManagers.chartOptions.labels.push(key);
                      this.topManagers.series.push(this.topmanagers.top_managers.weekly[key].count);
                  }
              }
          }
          if (type == "monthly") {
               this.topManagers.chartOptions.labels.splice(
              0,
              this.topManagers.chartOptions.labels.length
              );
              this.topManagers.series.splice(0, this.topManagers.series.length);
              for (const key in this.topmanagers.top_managers.monthly) {
                  if (this.topmanagers.top_managers.monthly.hasOwnProperty(key)) {
                      this.topManagers.chartOptions.labels.push(key);
                      this.topManagers.series.push(this.topmanagers.top_managers.monthly[key].count);
                  }
              }
          }
          if (type == "yearly" ) {
               this.topManagers.chartOptions.labels.splice(
              0,
              this.topManagers.chartOptions.labels.length
              );
              this.topManagers.series.splice(0, this.topManagers.series.length);
              for (const key in this.topmanagers.top_managers.yearly) {
                  if (this.topmanagers.top_managers.yearly.hasOwnProperty(key)) {
                      this.topManagers.chartOptions.labels.push(key);
                      this.topManagers.series.push(this.topmanagers.top_managers.yearly[key].count);
                  }
              }
          }
      },
     
      transportKppFunction(type) {
        if (type == "weekly" || type == "daily") {
               this.transportList.chartOptions.labels.splice(
              0,
              this.transportList.chartOptions.labels.length
              );
              this.transportList.series.splice(0, this.transportList.series.length);

              for (const key in this.transportkpp.transport_kpp.weekly) {
                  if (this.transportkpp.transport_kpp.weekly.hasOwnProperty(key)) {
                      this.transportList.chartOptions.labels.push(key);
                      this.transportList.series.push(this.transportkpp.transport_kpp.weekly[key].count);
                  }
              }
          }
          if (type == "monthly") {
               this.transportList.chartOptions.labels.splice(
              0,
              this.transportList.chartOptions.labels.length
              );
              this.transportList.series.splice(0, this.transportList.series.length);
              for (const key in this.transportkpp.transport_kpp.monthly) {
                  if (this.transportkpp.transport_kpp.monthly.hasOwnProperty(key)) {
                      this.transportList.chartOptions.labels.push(key);
                      this.transportList.series.push(this.transportkpp.transport_kpp.monthly[key].count);
                  }
              }
          }
          if (type == "yearly" ) {
               this.transportList.chartOptions.labels.splice(
              0,
              this.transportList.chartOptions.labels.length
              );
              this.transportList.series.splice(0, this.transportList.series.length);
              for (const key in this.transportkpp.transport_kpp.yearly) {
                  if (this.transportkpp.transport_kpp.yearly.hasOwnProperty(key)) {
                      this.transportList.chartOptions.labels.push(key);
                      this.transportList.series.push(this.transportkpp.transport_kpp.yearly[key].count);
                  }
              }
          }
      },
      reasonByOperationFunction(type) {
        if (type == "weekly" || type == "daily") {
               this.reasonByOperationChart.chartOptions.labels.splice(
              0,
              this.reasonByOperationChart.chartOptions.labels.length
              );
              this.reasonByOperationChart.series.splice(0, this.reasonByOperationChart.series.length);

              for (const key in this.reasonbyoperation.operation_reasons.weekly) {
                  if (this.reasonbyoperation.operation_reasons.weekly.hasOwnProperty(key)) {
                      this.reasonByOperationChart.chartOptions.labels.push(key);
                      this.reasonByOperationChart.series.push(this.reasonbyoperation.operation_reasons.weekly[key].count);
                  }
              }
          }
          if (type == "monthly") {
               this.reasonByOperationChart.chartOptions.labels.splice(
              0,
              this.reasonByOperationChart.chartOptions.labels.length
              );
              this.reasonByOperationChart.series.splice(0, this.reasonByOperationChart.series.length);
              for (const key in this.reasonbyoperation.operation_reasons.monthly) {
                  if (this.reasonbyoperation.operation_reasons.monthly.hasOwnProperty(key)) {
                      this.reasonByOperationChart.chartOptions.labels.push(key);
                      this.reasonByOperationChart.series.push(this.reasonbyoperation.operation_reasons.monthly[key].count);
                  }
              }
          }
          if (type == "yearly" ) {
               this.reasonByOperationChart.chartOptions.labels.splice(
              0,
              this.reasonByOperationChart.chartOptions.labels.length
              );
              this.reasonByOperationChart.series.splice(0, this.reasonByOperationChart.series.length);
              for (const key in this.reasonbyoperation.operation_reasons.yearly) {
                  if (this.reasonbyoperation.operation_reasons.yearly.hasOwnProperty(key)) {
                      this.reasonByOperationChart.chartOptions.labels.push(key);
                      this.reasonByOperationChart.series.push(this.reasonbyoperation.operation_reasons.yearly[key].count);
                  }
              }
          }
      },
      socialFunction() {
        this.clearSocialFunction();
          for (const key in this.source.candidate_social) {
              if (this.source.candidate_social.hasOwnProperty(key)) {
                  this.social.chartOptions.labels.push(key);
                  this.social.series.push(
                      this.source.candidate_social[key].count
                  );
              }
          }
         this.$refs["apexChartSalary"].updateOptions(this.apexChartSalary);
      },
      educationFunction() {
          this.education.chartOptions.labels.splice(
              0,
              this.education.chartOptions.labels.length
          );
          this.education.series.splice(0, this.education.series.length);
          for (const key in this.staffbyeducation.staff_education) {
              if (this.staffbyeducation.staff_education.hasOwnProperty(key)) {
                  this.education.chartOptions.labels.push(key);
                  this.education.series.push(
                      this.staffbyeducation.staff_education[key].count
                  );
              }
          }
      },
      genderFunction() {
          this.gender.chartOptions.labels.splice(
              0,
              this.gender.chartOptions.labels.length
          );
          this.gender.series.splice(0, this.gender.series.length);
          for (const key in this.genderstatistic.count_by_gender) {
              if (this.genderstatistic.count_by_gender.hasOwnProperty(key)) {
                  this.gender.chartOptions.labels.push(key);
                  this.gender.series.push(
                      this.genderstatistic.count_by_gender[key].count
                  );
              }
          }
      },
      filialFunction() {
          this.filial.chartOptions.labels.splice(
              0,
              this.filial.chartOptions.labels.length
          );
          this.filial.series.splice(0, this.filial.series.length);

          for (const key in this.averagesalarybybranches.number_of_staff_filial) {
              if (this.averagesalarybybranches.number_of_staff_filial.hasOwnProperty(key)) {
                 
                  this.filial.chartOptions.labels.push(
                      this.averagesalarybybranches.number_of_staff_filial[key].name
                  );
                  this.filial.series.push(
                      this.averagesalarybybranches.number_of_staff_filial[key].amount
                  );
              }
          }
      },
      telegramStaffFunction() {
          this.telegramStaff.chartOptions.labels.splice(
              0,
              this.telegramStaff.chartOptions.labels.length
          );
          this.telegramStaff.series.splice(0, this.telegramStaff.series.length);

          for (const key in this.candidate.candidate.telegramStaff) {
              if (this.candidate.candidate.telegramStaff.hasOwnProperty(key)) {
                 
                  this.telegramStaff.chartOptions.labels.push(
                      this.candidate.candidate.telegramStaff[key].name
                  );
                  this.telegramStaff.series.push(
                      this.candidate.candidate.telegramStaff[key].amount
                  );
              }
          }
      },
      typeFunction() {
          this.types.chartOptions.labels.splice(
              0,
              this.types.chartOptions.labels.length
          );
          this.types.series.splice(0, this.types.series.length);
          for (const key in this.staffworktype.number_of_staff_by_type) {
              if (this.staffworktype.number_of_staff_by_type.hasOwnProperty(key)) {
                  this.types.chartOptions.labels.push(key);
                  this.types.series.push(
                      this.staffworktype.number_of_staff_by_type[key].count
                  );
              }
          }
      },
      clearApexChar() {
          this.apexChartBar.chartOptions.xaxis.categories.splice(
              0,
              this.apexChartBar.chartOptions.xaxis.categories.length
          );
          this.apexChartBar.series[0].data.splice(
              0,
              this.apexChartBar.series[0].data.length
          );
          this.apexChartBar.series[1].data.splice(
              0,
              this.apexChartBar.series[1].data.length
          );
          this.apexChartBar.series[2].data.splice(
              0,
              this.apexChartBar.series[2].data.length
          );
      },
      clearEmployeeKpp() {
          this.employeeKpps.chartOptions.xaxis.categories.splice(
              0,
              this.employeeKpps.chartOptions.xaxis.categories.length
          );
          this.employeeKpps.series[0].data.splice(
              0,
              this.employeeKpps.series[0].data.length
          );
          this.employeeKpps.series[1].data.splice(
              0,
              this.employeeKpps.series[1].data.length
          );
      },
      clearTmsFunction() {
          this.tmsChart.chartOptions.xaxis.categories.splice(
              0,
              this.tmsChart.chartOptions.xaxis.categories.length
          );
          this.tmsChart.series[0].data.splice(
              0,
              this.tmsChart.series[0].data.length
          );
          this.tmsChart.series[1].data.splice(
              0,
              this.tmsChart.series[1].data.length
          );
      },
      clearApexCharVisitors(){
            this.visitorsChart.chartOptions.xaxis.categories.splice(
                0,
                this.visitorsChart.chartOptions.xaxis.categories.length
            );
            this.visitorsChart.series[0].data.splice(
                0,
                this.visitorsChart.series[0].data.length
            );
          
      },
      clearApexChartSalary() {
          this.apexChartSalary.chartOptions.xaxis.categories.splice(
              0,
              this.apexChartSalary.chartOptions.xaxis.categories.length
          );
          this.apexChartSalary.series[0].data.splice(
              0,
              this.apexChartSalary.series[0].data.length
          );
           this.apexChartSalary.series[1].data.splice(
              0,
              this.apexChartSalary.series[1].data.length
          );
      },
      clearWorkPlaceChart() {
          this.worksPlace.chartOptions.xaxis.categories.splice(
              0,
              this.worksPlace.chartOptions.xaxis.categories.length
          );
          this.worksPlace.series[0].data.splice(
              0,
              this.worksPlace.series[0].data.length
          );
          
      },
      clearSocialFunction(){
        this.social.chartOptions.labels.splice(
          0,
              this.social.chartOptions.labels.length
          );
          this.social.series.splice(0, this.social.series.length);
      },
      cleardistrictChart(){
        this.districtByStaff.chartOptions.xaxis.categories.splice(
              0,
              this.districtByStaff.chartOptions.xaxis.categories.length
          );
          this.districtByStaff.series[0].data.splice(
              0,
              this.districtByStaff.series[0].data.length
          );
          this.districtByStaff.series[1].data.splice(
              0,
              this.districtByStaff.series[1].data.length
          );
          this.districtByStaff.series[2].data.splice(
              0,
              this.districtByStaff.series[2].data.length
          );
      },
      clearcomplaintProfileChart(){
         this.compleinProfile.chartOptions.xaxis.categories.splice(
              0,
              this.compleinProfile.chartOptions.xaxis.categories.length
          );
          this.compleinProfile.series[0].data.splice(
              0,
              this.compleinProfile.series[0].data.length
          );
      },
      showStaffList(branch_id, method) {
        this.showUseerModal = true;
        this.child.branch_id = branch_id;
        this.child.method = method;
      },
      showComeStaffList(method, branch_id) {
        this.showComeUseerModal = true;
        this.child_come.branch_id = branch_id;
        this.child_come.method = method;
      },
      closeUserModal(val) {
        this.showUseerModal = val;
      },
      showTmsInfo(id) {
        this.showTmsModal = true;
        this.child.id = id;
      },
      showMostToiletStaffInfo(id) {
        this.staff.id = id;
        this.showMostToiletStaffModal = true;
        console.log(this.staff);
      },
      closeTmsModal(val) {
        this.showTmsModal = val;
      },
      closeMostToiletStaffModal(val) {
        this.showMostToiletStaffModal = val;
      },
      closeComeUserModal(val) {
        this.showComeUseerModal = val;
      },
      drawerOpened(ref) {
        if (this.$refs[ref]) {
          if (_.isFunction(this.$refs[ref].opened)) {
            this.$refs[ref].opened(this.child);
          }
        }
      },
      drawerTwoOpened(ref) {
        if (this.$refs[ref]) {
          if (_.isFunction(this.$refs[ref].opened)) {
            this.$refs[ref].opened(this.child_come);
          }
        }
      },
      drawerTmsOpened(ref) {
        if (this.$refs[ref]) {
          if (_.isFunction(this.$refs[ref].opened)) {
            this.$refs[ref].opened(this.child);
          }
        }
      },
       drawerMostToiletStaffOpened(ref) {
        if (this.$refs[ref]) {
          if (_.isFunction(this.$refs[ref].opened)) {
            this.$refs[ref].opened(this.staff);
          }
        }
      },
      pushToProfile(staff) {
          let route = this.$router.resolve({
            name: "profile",
            params: {
              id: staff.id,
            },
          });

          window.open(route.href, "_blank");
      },
  }
}
</script>

<style lang="scss">
.green{
background-color: rgb(50 140 5 / 56%) !important;
border-color: rgb(50 140 5 / 56%) !important;
}
.el__cardnight {
  background: #100e1d !important;
  border: 1px dashed #323248 !important;
}
.el__cardday{
  background: #fff !important;
  border: 1px solid #fff;
}
.inputhome__day .el-input__inner {
background: inherit !important;
color: rgb(40, 39, 39) !important;
border-color: #92929f !important;
}
.inputhome__day .el-input__inner::-webkit-input-placeholder {
color: #92929f;
}
.inputhome__day .el-select .el-input .el-select__caret {
color: #67676e
}
.epty_data{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
min-height: 400px;
color: #fff;
  font-size: 20px;
}
.epty_data img{
width: 160px;
}
.apx-legend-position-right{
height: auto !important;
}
.agents__table{
width: 100%;
}
.agents__table tbody tr td {
  padding: 8px 5px;
}
.agents__center {
  text-align: center;
}
</style>

