import { state } from "../../state";

export const getters = {
    token: state => state.token,
    gomax_token: state => state.gomax_token,
    gocrm_token: state => state.gocrm_token,
    contract: state => state.contract,
    phone_number: state => state.phone_number,
    name: state => state.name,
    role: state => state.role,
    slug: state => state.slug,
    permissions: state => { return (state.role) ? state.role.permissions : state.role.permissions },
    expires_in: state => state.expires_in,
    status: state=>state.status, 
    user: state=>state.user   
};
