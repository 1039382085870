export const ru_messages = {
  yes: 'Да',
  no: 'Нет',
  n: "№",
  create: "Создать",
  age: "Возраст",
  limit: "Кол-во участников",
  new_m: "Новый  {m}",
  edit_m: "Изменить  {m}",
  edit: "Изменить",
  main: 'Главная',
  search: 'Найти',
  gender: "Пол",
  position_2: "Должность (2-я)",
  department: "Отделы",
  department_: "Отдел",
  department_y: "Отделы",
  settings: "Настройки",
  datas: "Дата",
  full_name: "Имя и Фамилия",
  created_at: "Дата создания",
  updated_at: "Дата изменения",
  name: "Наименование",
  nameGiven: "Название",
  nameFull: "Имя",
  requirements: "Требования",
  requirements_y: "Требовании",
  reason: "Причина",
  reasontype: "Тип причины",
  staffAssentReason:"Отпрашивания",
  reason_y: "Причины",
  priority: " Приоритет",
  priority_y: " Приоритеты",
  position: "Должность",
  position_p: "Должности",
  graphicType: "Тип графика",
  graphicType_p: "Типы графика",
  company: "Компания",
  company_y: "Компании",
  language: "Знание языков",
  language2: "Знание языков",
  language_y: "Знание языков",
  currency: "Валюта",
  currency_y: "Валюту",
  salary_currency: "Валюта З/П",
  salary_currency_y: "Валюту З/П",
  branch: "Филиалы",
  branch_a: "Филиал",
  branch_parental: "Родительский филиал",
  skill: "Навыки",
  skill_y: "Навык",
  total_working_day: "Рабочая неделя",
  graphic: "График работы",
  language_graphic_type: "Пожалуйста, введите название",
  employmentType: "Вид занятости",
  province: "Город",
  region: "Город",
  region_: "Регион",
  vacancy: "Вакансии",
  last_name: "Фамилия",
  middle_name: "Отчество",
  date_of_birth: "Дата рождения",
  phone_number: "Телефон",
  email: "Эл. адрес",
  status: "Статус",
  role: "Роли",
  security_location: "Зона",
  securityLocations_y: "Зона",
  deviceType: "Типы Устройств",
  deviceType_y: "Типы Устройств",
  department_title: "Отделы и подразделения",
  parent_department_id: "Родительский отдел",
  min_wage_y: "Мин  З/П",
  max_wage_y: "Макс З/П",
  phone_number_y: "Номер тел",
  summary: "Резюме",
  UploadFile: "Загрузить файл",
  UploadFileText: " файлы jpg / png размером менее 500 КБ ",
  serial_number: "Серийный номер",
  ip_address: "IP адрес",
  device: "Устройство",
  device_y: "Устройство",
  income: "Уровень дохода",
  work_type: "Статус Сотрудника",
  staff_work_type: "Тип работы",
  district_id: "Район ",
  district: "Район",
  nation: "Нация",
  nation_y: "Нация",
  code: "Код",
  okurs: "Обратный курс",
  profile: "Профиль",
  dateAdoption: "Дата принятия",
  staffTest: "Испытательный ",
  operating: "Действующий",
  dismissed: "Уволенный",
  save: "Сохранить",
  close: "Закрыть",
  candidates: "Кандидаты",
  find: "поиск",
  delete: "Удалить",
  daily: "Ежедневно",
  from: "От",
  before: "До",
  dinner: "Обед",
  users: "Пользователи",
  user: "Пользователь",
  password: "Пароль",
  confirm_password: "Повторить пароль",
  finance: "Финансы",
  client: "Клиент",
  access_rights: "Права доступа",
  active: "Активный",
  inactive: "Не активный",
  working_hours_report: "Отчет по рабочему времени",
  age: "Возраст",
  nv_monitoring: "Мониторинг НВ",
  new_incoming_payment: "Новый входящий платеж",
  incoming: "Входящий",
  outgoing: "Исходящий",
  employees: "Сотрудники",
  payment_type: "Тип оплаты",
  amount: "Сумма",
  check: "Счет",
  check_a: "Счета",
  salary_type: "Тип зарплаты",
  salary: "Зарплата",
  bank: "Банк",
  checking_account: "Расчетный счет",
  expiration_date: "Дата оканчания",
  comment: "Комментарий",
  overtime: "Сверхурочный",
  total: "Итого:",
  being_late: "Опаздания",
  other_penalties: "Другие штрафы",
  penalties: "Штрафы",
  on_hands: "На руки",
  passport_number: "Серия паспорта",
  passport_issued_by: "Кем выдан паспорт",
  passport_valid_until: "Срок действия паспорта",
  inn: "ИНН",
  telegram:"Telegram",
  telegram_staff:"Telegram Staff",
  job: "Работа",
  hiring_date: "Дата найма",
  manager: "Руководитель",
  allow_th_employee_to_access_the_system: "Разрешить сотруднику доступ к системе.",
  allow_salary: "Зарплата.",
  add_candidates: "Добавить кандидатов",
  description: "Описание",
  photo: "Фото",
  min: "минимальный",
  max: "максимальный",
  recruiting: "Рекрутинг",
  desktop: "Рабочий стол",
  indicators: "Показатели",
  journal: "Журнал",
  quit: "Выйти",
  branch_name: "Название отдела",
  mfo: "МФО",
  number: "Номер",
  account: "Счет",
  card_number: "Номер карты",
  penalty_name: "Название штрафа",
  bonus_name: "Название бонуса",
  payment_date: "Дата платежа",
  adaptation: "Адаптация",
  agreement_employees: "Договора(СОТРУДНИКИ)",
  login: "Авторизоваться",
  permission: "Разрешения",
  synchronization: "Синхронизация",
  tests: "Тесты",
  wage: "Заработная плата",
  dafault: "По умолчанию",
  real_amount: "Реальная сумма",
  bonus_amount: "Бонусная сумма",
  payment_for: "Платеж за",
  family_location: "Семейное положение",
  url: "Ссылка",
  age: "Возраст",
  wages: "Зарплата",
  complete_form: "Заполните форму",
  KPP: "КПП ",
  guest: "Гость",
  card: "Машина",
  copyUrl: "Копировать URL",
  vacancyNotAvailable: "Вакансия недоступна",
  fire_staff: 'Увольнение',
  user_monitoring: 'Мониторинг пользователей',
  car_driver: "Основной шофёр",
  new_operation: "Новая Операционка",
  update_operation: "Изменение Операционки",
  transport: "Транспорт",
  arrival_date_time:"Дата и время прибытия",
  arrival_date: "Дата прибытия",
  arrival_time: "Время прибытия",
  difference: "Разница",
  managed_task: "Комментарии",
  employee_entrance: "Вход Сотрудника",
  employee_exit: "Выход сотрудника",
  entrance: "Вход",
  exit: "Выход",
  type: "Тип",
  district: "Район",
  min_wage: 'Минимальная зарплата',
  max_wage: 'Максимальная зарплата',
  min_age: 'Минимальный возвраст',
  max_age: 'Максимальный возвраст',
  description: 'Описание',
  symbol: 'Символ',
  staff: 'Сотрудники',
  active: 'Активный',
  inactive: 'Неактивный',
  role_is_not_set: 'Роль не назначена ',
  new: 'Новый',
  // amount: 'суммы',
  amount_a: 'Сумма',
  comment: 'Комментарий',
  attendance_type: "Тип посещаемости",
  port: "Порт",
  car: "Машина",
  guest_coming_reason: "К кому вы пришли?",
  visit_date: "Дата прихода",
  visit_date_time: "Дата и время прихода",
  visit_time: "Время прихода ",
  left_date: "Дата выхода",
  left_date_time: "Дата и время выхода",
  left_time: "Время выхода",
  car_number: "Номер машины",
  car_color: "Цвет машины",
  car_type: "Тип машины",
  car_model: "Модель машины",
  additional_information: "Дополнительная информация",
  add_guest: "Добавить гостя",
  add_company: "Добавить компанию",
  address: "Адрес",
  add_car: "Добавить машину",
  add_phone_number: "Добавьте номер телефона",
  visitor: "Посетитель",
  update_visitor: "Изменить посетителя",
  total_visit: "Всего посещения",
  car_information: "Информация об автомобиле",
  supported_formats: "Формат файла:  doc, docx, pdf, jpg, jpeg, gif, svg, png",
  size_limit_5: "Размер файла: 5 мб",
  candidates: "Кандидаты",
  attendance: "Посещаемость",
  time: "Время",
  attendance_type: "Тип посещаемости",
  img: "Фото",
  verfired_type: "Тип подтверждения",
  today: "Сегодня",
  refresh: "Обновить",
  exit_target: "Цель выхода",
  expected_emp: "План входа",
  chel: "Чел",
  factual_emp: "Факт входа",
  transport_type: "Тип автомобиля",
  client_transport: "Машина контрагента",
  company_transport: "Машина компании",
  product: "Продукт",
  product_name: "Наименование товара",
  quantity: "Количество",
  measurement_type: "Тип измерения",
  copy:"Копировать",
  add_new: "Добавить новый",
  shipping_letter:"Отгрузочное письмо",
  user_activity:"Активность пользователя",
  id:'Идентификатор',
  method_type:"Тип метода",
  browser_type:"Тип браузера",
  social: "Источник",
  where_know:"Откуда узнали",
  line:"Линия",
  accept:"Принять",
  education:"Образование",
  header:"Тема",
  title:"Текст",
  periot: "Дата взятия аванса",
  Tuesday:"Вторник",
  Wednesday:"Среда",
  Thursday:"Четверг",
  Friday:"Пятница",
  Saturday:"Суббота",
  Sunday:"Воскресенье",
  Monday:"Понедельник",
  'settings profile': 'Настройки профиля',
  'settingCompany': 'Информация о компаниях',
  'logout': 'Выйти',
  'category': 'Категория',
  'categories': 'Категории',
  'telegram_managers':'Телеграм сообщение',
  'telegram_manager':'Телеграм сообщение',
  vacation: "Учет отпусков",
  children:"Дети",
  newcomestaffs:"Недавно принятые на работу",
  completestaffprofile:"Процент заполненности профиля",
  staffTests:"Испытательные",
  operatings: "Действующие",
  dismisseds: "Уволенные",
  average_age:"Средний возраст",
  arrivals_and_departures:"Соотношение прихода и ухода",
  accepted_CV:"Принятые резюме",
  total_qty:"Общее кол-во",
  present:"Присутствуют",
  missing:"Отсуствуют",
  past_birthdays:"Прошедшие дни рождении",
  today_birthdays:"Сегодняшние дни рождении",
  expected_birthdays:"Ожидаемые дни рождении",
  count_gender:"Количество мужчин и женщин в компании",
  not_data:"Пока нет данных",
  reasons_for_layoffs:"Причины увольнения ",
  official_and_unofficial :"Количество официальных и неофициальных в компании",
  faceidregistration:"Регистрация Face ID",
  registered:"Зарегистрированные",
  not_registered:"Не зарегистрированные",
  compleinProfile:"Доля заполненных анкет по филиалам, %",
  worksPlace:"Стаж работы",
  salary_by_branches:"Средняя зарплата по филиалам",
  districtByStaff:"Численность сотрудников по регионам",
  will_arrive:"Приедут",
  not_come:"Не приедут",
  answer_expected:"Ожидается ответ",
  assign_role:"Назначить роль",
  excel:"Excel",
  weekly:"Неделя",
  monthly:"Месяц",
  yearly:"Год",
  coming:"Приход",
  pending:"В ожидании",
  additional_rest:"Доп.ост",
  o_salary: "Оплата труда",
  bonuses: "Бонусы",
  avance:"Аванс",
  tms:"Въезд | Выезд ТМЦ",
  employee_kpp:"Прибытие | Отъезд Сотрудников",
  operation:"Операционка",
  company_machines:"Машины Компании",
  leave_requests:"Запросы на отгулы",
  take_assents:"Запросы на отгулы",
  report_card:"Табель",
  structure:"Структура",
  additional_work:"Дополнительная работа",
  additional_works:"Дополнительная работа",
  complaint:"Жалоба",
  calendar:"Календарь",
  fio:"ФИО",
  general_working_day:"Общий рабочий день",
  general_working_hour:"Общий рабочий час",
  records_of_the_month :"Отметки о явке и неявке на работу по датам месяца",
  unrevealed:"Не явленные",
  waiting:"Ожидающий",
  received:"Принято",
  denied:"Отказано",
  all:"Все",
  those_present:"Присутствующие",
  released:"Вышедшие",
  missing:"Отсутствующие",
  with_reason:"С причиной",
  without_reason:"Без причины",
  online:"Онлайн",
  confirm:"Подтвердженный",
  approved:"Одобренный",
  rejected:"Отклоненный",
  date_time:"Дата и время",
  performance_type:"Тип производительности",
  qty:"Kол-во",
  details:"Детали",
  permis:"разрешение",
  parental_permission:"Родительское разрешение",
  translated_resolution:"Переведенное разрешение",
  children_permission:"Дети разрешение",
  children_translated_permission:"Разрешение на перевод детей",
  telegram_password:"Телеграм пароль",
  last_active:"Последняя активность",
  subordinates:"Подчиненные",
  document:"Документы",
  file:"Файлы",
  additional_information:"Доп. информация",
  work_history:"История работы",
  cancel:"Отмена",
  personal:"Личное",
  limit_work_time:"Норма работы (час)",
  actual_working_time:"Фактическое время работы (час)",
  salary_rotation:"Заработная плата и ротации",
  rotation:"Ротации",
  quality_control:"Контроль качества",
  process:"Процесс",
  name_process:"Процесс имени",
  norms:"Норма",
  fact:"Факт",
  assigned_salary:"Назначенная заработная плата",
  last_month:"Прошлый месяц",
  current_month:"Текущий месяц",
  allowed:"Разрешенные",
  rejected:"Отклоненные",
  request_time:"Время запроса",
  licenses_and_certificates:"Лицензии и сертификаты",
  gave_out:"Выдал",
  issued:"Выдано",
  release_date:"Дата выпуска",
  health_information:"Информация о здоровье",
  growth:"Рост",
  weight:"Вес",
  blood_type:"Группа крови",
  locations_health_information:"Места Информация о здоровье",
  foreign_languages:"Иностранные языки",
  degree:"Степень",
  lang:"Язык",
  mobil_number:"Мобильный номер",
  work_phone:"Рабочий телефон",
  facebook_url:"URL Facebook:",
  instagram_url:"URL Instagram:",
  twitter_url:"URL Twitter:",
  linkedIn_url:"URL LinkedIn:",
  telegram_username:"Имя пользователя в Telegram",
  tiktok_username:"Имя пользователя в Tiktok",
  educational_institution:"Учебное заведение",
  faculty:"Факультет",
  beginning_date:"Дата начала",
  expiration_date:"Дата окончания",
  skill:"Умение",
  not_coming_day:"Ненаступивший день",
  hours_or_more:"{m} и более",
  less_than_hours:"Менее {m}",
  day_absence:"Выходной день",
  not_come_work_day:"Не пришел на работу",
  complaints:"Жалобы",
  subject_complaint:"Тема жалобы",
  izn:"Изн",
  date_of_departure:"Дата ухода",
  place_work:"Место работы",
  place_work_name:"Название место работы",
  data_from_to:"Дата от и до",
  display_and_wallpaper: "Рабочий стол и заставка",
  dynamic_wallpaper: "Динамические обои",
  notes:"Примечания",
  from_data:"Дата от",
  to_data:"Дата до",
  add_document:"Добавить документ",
  labor_contract:"Трудовой договор",
  material_liability:"Материальная ответственность",
  confidentiality_agreement:"Договор конфеденциальности",
  employment_contract:"Расторжение трудового контракта",
  finance_training:"Обучение по финансированию",
  plastic_card:"Пластиковая карта",
  card_number:"Номер карты",
  date_of_expiry:"Дата истечения срока",
  card_organ:"Карточный орган",
  amount_per_hour:"Сумма в час",
  unofficial_amount:"Неофициальная сумма",
  official_amount:"Официальная сумма",
  type_salary:"Тип З/П",
  amount_money:"Количество денег",
  in_hour:"В час",
  access_control:"Контроль доступа",
  id_employee:"ID сотрудника",
  contact:"Контакт",
  add_skill:"Добавить навык",
  Level:"Уровень",
  // =================
  country_passport:"Паспорт страны",
  Send_Manager:"Send Manager",
  departure_time:"Время отправления",
  access_time:"Время доступа",
  model:"Модельный",
  izn_password:"Пароль для регистрации в боте",
  sort_panel: "Приборная панель",
  schedule:'Расположение',
  new_version:'Новая версия',
  old_version:'Старая версия',
  causes_operating_system:'Причины операционки',
  active_machines:'Часто используемые машины компании',
  visitors_to_company_employees:'Посетители к сотрудникам компании',
  number_of_visitors_periods:'Кол-во посетителей по периодам',
  late_to_works:'Опоздавшие сотрудники',
  coming_date_time:'Дата и время прибытия',
  coming_time:'Время прибытия',
  in_count:'Кол-во входов',
  out_count:'Кол-во выходов',
  total_toilet_minuts:'Общее время',
  info_tms_statistic:'Недавние Въезд|Выезд ТМЦ',
  info_toilet_statistic:'Топ-20 посетителей уборной',
  director:"Директор",
  country:"Страна",
  logo:"Логотип",
  web_site:"Веб-сайт",
  application:"Заявление",
  explanatoryLetter:"Объяснительная",
  explanatory_letters:"Объяснительная",
  active_works:"Активный Сотрудник | Чистая территория",
  active_work:"Активный Сотрудник | Чистая территория",
  active_person:"Активный Сотрудник",
  prosperous_place:"Чистая территория",
  parent_company_id: "Родительская компания",
  manual:"Созданный руководством",
  leader:"Директор",
  numeric_currency_code:'Цифровой код валюты',
  letter_currency_code:'Буквенный код валюты',
  payed:"To'langan",
  advance_salary:"Аванс",
  advance_salaries:"Аванс",
  advance_request: " аванса",
  pentaly_salary:"Штрафы",
  explanatory_letters: "Объяснительные письма",
  application_letter: "Заявительные письма",
  applications: "Заявительные письма",
  prizes: "Премии",
  hour:'ч',
  minut:'м',
  dateuvalen: "Дата увольнения",
  enoughStaff: "Не хватает сотрудников",
  in_work: "На сегодня пришли",
  not_come_work: "Не пришли",
  sick_leave: "В больничном",
  sick_leaves: "Больничный",
  on_vacation: "В отпуске",
  business_trip: "В командировке",
  late_to_work: "Опоздавшие",
  accepted_take_assent: "Отпросились",
  not_reason:'Без причины',
  start_date:'Дата начала',
  end_date:'Дата окончания',
  start_time:'Время начала',
  end_time:'Время окончания',
  organizations:'Организация',
  responsible:'Ответственный',
  business_travelers:'Командирумые',
  business_trip_purpose :'Цель командировки',
  business_trip_tasks :'Задачи в командировки',
  type_expense:'Типы расходов',
  type_expenses:'Типы расходов',
  payments_expense:'Платежи за расходы',
  payments_expenses:'Платежи за расходы',
  business_trips:'Командировки',
  Break: 'Перерыв',
  sanction: 'Санкции',
  new_sanctions: 'Новые санкции',
  sanction_name: "Название санкции",
  dosnt_have_salary:'Ish haqqi kiritilmaganlar',
  have_salary:'Ish haqqi kiritilganlar',
  value:"Ценность",
  percent:"Процент",
  value_type:"Тип значения",
  bonus_penalty_reasons:'Причины санкции | бонусы',
  work_times:'Рабочие часы',
  online_works:'Дистанционная работа',
  vacation_managements:"Управление отпуском",
  vacation_management:"Управление отпуском",
  accept_vacation_managment:"Месяц отпуска",
  not_attached:"Не прикреплен",
  pnfl:"ПИНФЛ",
  vacation_graphics:'График отпуска',
  vacation_graphic:'График отпуска',
  official_date:"Дата перехода на официальный" ,
  back: "Назад",
  send_channel:"Отправить канал",
  vacation_planes:'План отпуска',
  anecdotes:"Анекдоты",
  anecdote:"Анекдот",
  content:"Содержание",
  request:"Запросы",
  fixed:'Фиксированная оплата',
  hourly:'Почасовая оплата',
  income_tax :'Подоходный налог',
  start_date_time:"Дата и время начала",
  end_date_time:"Дата и время окончания",
  settingApp:'Настройки GOHR',
  holiday_work_contribution:'Взнос на работу в праздничные дни',
  question_category_list:'Категория вопроса',
  question_direction_list:'Направление вопроса',
  question_type_list:'Тип вопроса',
  validator:'Валидатор',
  telegram_quiz:'Телеграм викторина тест',
  started_date:'Дата начала',
  point:'Балл',
  questions:'Вопросы',
  option:'Вариант',
  quiz_plan:'План викторины',
  daly_question_count:'Ежедневное количество вопросов',
  from_date:"Дата от",
  to_date:"Дата до",
  send_question_time:'Отправить время',
  what_for:'Для чего',
  media_type:'Тип носителя',
  closed:'Завершенный',
  started:'Не завершен',
  sanction_type:'Тип санкции',
  sanction_type_items:'Элемент типа санкции',
  fix:'Fix',
  create_sanction_late:'Yaratilish vaqti (daqiqa)',
  is_free:'Свободное посещение (сдельная оплата)',
  by_connecting_to_the_device:'При подключении к устройству',
  in_vacation:'В отпуске',
  min_point:'Мин Балл',
  check_level:'проверить уровень',
  lay_off_date:'Дата увольнения',
  application_dismissals:'Заявления об увольнении',
  send_acceptor:"Отправить в Telegram",
  inventories:'Инвентарь',
  inventory:'Инвентарь',
  inventory_categories:'Категории инвентаря',
  inventory_category:'Категория инвентаря',
  all_amount:'Общая сумма',
  online_or_ofline:'Дистанционная и/или Дополнительная',
  all_work_days :'Общие рабочие дни',
  normal_salary:'Оплата по норме',
  transport_salary:'Транспортные расходы и поощрения',
  hourly_salary:'Повременная оплата',
  all_paid_salary:'Общая требуемая оплата',
  'Общие сохраненные':'Общие сохраненные',
  candidate_new:'Недавно отправлено',
  candidate_called_interview:'Позвали на собеседование',
  candidate_approved_employment:'Прошел собеседование',
  monthly_limit_work_day:'Нормативный день в месяц',
  monthly_limit_work_hour:'Нормативное время в месяц',
  plane_graphic:'План графика',
  logout_question:'Вы уверены, что выйдете из системы?',
  operation_canceled:'Операция отменена!',
  device_count_type:'Тип счетчика устройств',
  date_uvalen_day_count:'Срок увольнения',
  annual_medical_leave:"1-й ежегодный больничный",
  oficial_salary:'Оф / С',
  un_oficial_salary:'Н / С',
  all_salary:'Общая / С',
  work_day_count:'Рабочий день',
  average_work_time:'Рабочий час',
  mounth_limit:'Стандартное <br/> рабочее времяиш',
  all_work_time:'Общий<br/> рабочий час',
  online_and_additional_works:'<div >Дистанционная или<br/> Дополнительная</div>',
  all_worked_day:'Общий рабочий день',
  per_hour:'Повременная оплата',
  normal_pay_salary:'Норма буйича<br/> иш хаки',
  all_pay_salary:'Общая <br/> требуемая оплата',
  all_must_paying_salary:'Общая требуемая <br/> оплата',
  this_month_vacation:'Отпуск в этом месяце',
  next_month_vacation:'Отпуск на следующем месяце',
  applicationType:'Тип заявления',
  seer:'Информированный сотрудник'
}
