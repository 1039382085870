export const en_messages = {
  yes: 'Yes',
  no: 'No',
  n: "№",
  create: "Create",
  age: "Age",
  limit: "Number of members",
  new_m: "New  {m}",
  edit_m: "Edit  {m}",
  edit: "Edit",
  main: 'Homepage',
  search: 'Search',
  gender: "Gender",
  position_2: "Position (2nd)",
  department: "Departments",
  department_: "Department",
  department_y: "Departments",
  settings: "Settings",
  datas: "Date",
  full_name: "Full name",
  created_at: "Creation date",
  updated_at: "Updated date",
  name: "Name",
  nameGiven: "Title",
  nameFull: "Full name",
  requirements: "Requirements",
  requirements_y: "Requirements",
  reason: "Reason",
  reasontype: "Type of reason",
  staffAssentReason:"Begging off",
  reason_y: "Reasons",
  priority: " Priority",
  priority_y: " Priorities",
  position: "Position",
  position_p: "Positions",
  graphicType: "Type of graph",
  graphicType_p: "Types of graph",
  company: "Company",
  company_y: "Companies",
  language: "Language abilities",
  language2: "Language abilities",
  language_y: "Language abilities",
  currency: "Currency",
  currency_y: "Currency",
  salary_currency: "Salary currency",
  salary_currency_y: "Salary currency",
  branch: "Branches",
  branch_a: "Branch",
  branch_parental: "Parent Branch",
  skill: "Skills",
  skill_y: "Skill",
  total_working_day: "Working days",
  graphic: "Work schedule",
  language_graphic_type: "Please enter the name",
  employmentType: "Type of employment",
  province: "Province",
  region: "Region",
  region_: "Region",
  vacancy: "Vacancies",
  last_name: "last name",
  middle_name: "Patronymic",
  date_of_birth: "Date of birth",
  phone_number: "Phone number",
  email: "Email",
  status: "Status",
  role: "Roles",
  security_location: "Security location",
  securityLocations_y: "Security location",
  deviceType: "Types of Devices",
  deviceType_y: "Types of Devices",
  department_title: "Departments and divisions",
  parent_department_id: "parent department",
  min_wage_y: "Min  wage",
  max_wage_y: "Min wage",
  phone_number_y: "Phone number",
  summary: "CV",
  UploadFile: "upload file",
  UploadFileText: " jpg/png files less than 500 KB in size ",
  serial_number: "Serial number",
  ip_address: "IP address",
  device: "Device",
  device_y: "Device",
  income: "Income level",
  work_type: "Employee Status",
  staff_work_type: "Employee's type of work",
  district_id: "District ",
  district: "District",
  nation: "Nation",
  nation_y: "Nation",
  code: "Code",
  okurs: "Reverse course",
  profile: "Profile",
  dateAdoption: "date of acceptance",
  staffTest: "trial period ",
  operating: "Active",
  dismissed: "dismissed",
  save: "Save",
  close: "Close",
  candidates: "Candidates",
  find: "Search",
  delete: "Delete",
  daily: "DAily",
  from: "from",
  before: "before",
  dinner: "Lunch",
  users: "Users",
  user: "User",
  password: "password",
  confirm_password: "repeat password",
  finance: "finance",
  client: "Client",
  access_rights: "access rights",
  active: "Active",
  inactive: "Inactive",
  working_hours_report: "Working Time Report",
  age: "Age",
  nv_monitoring: "Monitoring NV",
  new_incoming_payment: "New incoming payment",
  incoming: "incoming",
  outgoing: "outgoing",
  employees: "employees",
  payment_type: "Payment type",
  amount: "Amount",
  check: "Check",
  check_a: "Cheks",
  salary_type: "Salary types",
  salary: "Salary",
  bank: "bank",
  checking_account: "Checking acount",
  expiration_date: "Date of termination",
  comment: "comment",
  overtime: "Overtime",
  total: "Total:",
  being_late: "Delays",
  other_penalties: "Other fines",
  penalties: "Fines",
  on_hands: "On hands",
  passport_number: "Passport series",
  passport_issued_by: "Passport ussued by",
  passport_valid_until: "Passport valid until",
  inn: "ИНН",
  telegram:"Telegram",
  telegram_staff:"Telegram Staff",
  job: "Job",
  hiring_date: "Hiring date",
  manager: "Manager",
  allow_th_employee_to_access_the_system: " Allow the employee to access the system.",
  allow_salary: "Salary.",
  add_candidates: "Add candidates",
  description: "Description",
  photo: "Photo",
  min: "Minimum",
  max: "Maximum",
  recruiting: "Recruiting",
  desktop: "Desktop",
  indicators: "Indicators",
  journal: "Journal",
  quit: "Exit",
  branch_name: "Branch name",
  mfo: "Bank code",
  number: "Number",
  account: "Account",
  card_number: "Card number",
  penalty_name: "Fine name",
  bonus_name: "Bonus name",
  payment_date: "Payment date",
  adaptation: "Adaptation",
  agreement_employees: "Agreements (employees",
  login: "Log in",
  permission: "Permission",
  synchronization: "Synchronization",
  tests: "Tests",
  wage: "Wage",
  dafault: "By default",
  real_amount: "Real amount",
  bonus_amount: "Bonus ammount",
  payment_for: "Payment for",
  family_location: "Marital status",
  url: "Url",
  age: "Age",
  wages: "Wage",
  complete_form: "Complete form",
  KPP: "КПП ",
  guest: "Quest",
  card: "Car",
  copyUrl: "Copy URL",
  vacancyNotAvailable: "the vacancy is not available",
  fire_staff: 'Firing',
  user_monitoring: 'user monitoring',
  car_driver: "main car driver",
  new_operation: "New operation",
  update_operation: "updating the operation",
  transport: "Transport",
  arrival_date_time:"Date and time of arrival",
  arrival_date: "Arrival date",
  arrival_time: "Arrival time",
  difference: "Difference",
  managed_task: "Task managment",
  employee_entrance: "Employee entrance",
  employee_exit: "Employee exit",
  entrance: "Entance",
  exit: "Exit",
  type: "Type",
  district: "District",
  min_wage: 'Minimum wage',
  max_wage: 'Maximum wage',
  min_age: 'Minimum age',
  max_age: 'Maximum age',
  description: 'Description',
  symbol: 'Symbol',
  staff: 'Employees',
  active: 'Active',
  inactive: 'Inactive',
  role_is_not_set: 'no role assigned ',
  new: 'New',
  amount: 'Ammount',
  amount_a: 'Ammount',
  comment: 'Comment',
  attendance_type: "Type of attendance",
  port: "Port",
  car: "Car",
  guest_coming_reason: "Who did you come to?",
  visit_date: "Date of arrival",
  visit_date_time: "Date and time of arrival",
  visit_time: "Arrival time ",
  left_date: "Arrival date",
  left_date_time: "Date and time of leaving",
  left_time: "Time of leaving",
  car_number: "Car number",
  car_color: "Car color",
  car_type: "Type of the car",
  car_model: "Model of the car",
  additional_information: "Additional information",
  add_guest: "Add a guest",
  add_company: "Add a company",
  address: "address",
  add_car: "Add a car",
  add_phone_number: "add a phone number",
  visitor: "Visitor",
  update_visitor: "Update the visitor",
  total_visit: "Total visitor",
  car_information: "Car information",
  supported_formats: "Supported formats:  doc, docx, pdf, jpg, jpeg, gif, svg, png",
  size_limit_5: "Size limit: 5 мб",
  candidates: "Candidates",
  attendance: "Attendance",
  time: "Time",
  attendance_type: "Attendance type",
  img: "Image",
  verfired_type: "Type of confirmation",
  today: "Today",
  refresh: "Update",
  exit_target: "Exit plan",
  expected_emp: "Entarance plan",
  chel: "Чел",
  factual_emp: "The fact of entry",
  transport_type: "Type of transport",
  client_transport: "Client transport",
  company_transport: "Company transport",
  product: "Product",
  product_name: "Product name",
  quantity: "Quantity",
  measurement_type: "measurement_type",
  copy:"Copy",
  add_new: "Add the new one",
  shipping_letter:"Shipping letter",
  user_activity:"User activity",
  id:'ID',
  method_type:"Method type",
  browser_type:"Browser_type",
  social: "Source",
  where_know:"How did you find out",
  line:"Line",
  accept:"Accept",
  education:"Education",
  header:"Topic",
  title:"Text",
  periot: "Date of taking the advance payment",
  Tuesday:"Tuesday",
  Wednesday:"Wednesday",
  Thursday:"Thursday",
  Friday:"Friday",
  Saturday:"Saturday",
  Sunday:"Sunday",
  Monday:"Monday",
  'settings profile': 'profile settings',
  'settingCompany': 'Company information',
  'logout': 'Log out',
  'category': 'Category',
  'categories': 'Categories',
  'telegram_managers':'Telegram messages',
  'telegram_manager':'Telegram message',
  vacation: "Vacation accounting",
  children:"Children",
  newcomestaffs:"Recently hired",
  completestaffprofile:"Percentage of profile completion",
  staffTests:"Testing",
  operatings: "Active",
  dismisseds: "Fired",
  average_age:"Average age",
  arrivals_and_departures:"The ratio of arrival and departure",
  accepted_CV:"Accepted CV",
  total_qty:"Total number",
  present:"Present",
  missing:"Absent",
  past_birthdays:"Past birthdays",
  today_birthdays:"Today's birthdays",
  expected_birthdays:"Expected birthdays",
  count_gender:"Number of men and women in the company",
  not_data:"No data yet",
  reasons_for_layoffs:"Reasons for dismissal ",
  official_and_unofficial :"Number of official and unofficial employees in the company",
  faceidregistration:"Face ID Registration",
  registered:"Registrated",
  not_registered:"Not registered",
  compleinProfile:"Percentage of completed forms in branches, %",
  worksPlace:"Work experience",
  salary_by_branches:"Average salary in branches",
  districtByStaff:"Number of employees by region",
  will_arrive:"Will come",
  not_come:"Won't come",
  answer_expected:"The answer is expected",
  assign_role:"Assign a role",
  excel:"Excel",
  weekly:"Week",
  monthly:"Month",
  yearly:"Year",
  coming:"Coming",
  pending:"Waiting",
  additional_rest:"Доп.ост",
  o_salary: "Salary",
  bonuses: "Bonus",
  avance:"Prepayment",
  tms:"Entry | Exit ТМЦ",
  employee_kpp:"Arrival | Departure of Employees",
  operation:"Operating system",
  company_machines:"Company cars",
  leave_request:"Requests for time off",
  take_assents:"Requests for time off",
  report_card:"Report card",
  structure:"Structure",
  additional_work:"Extra work",
  additional_works:"Extra work",
  complaint:"Complaint",
  calendar:"Calendar",
  fio:"Full name",
  general_working_day:"Total working day",
  general_working_hour: "General working hour",
  records_of_the_month : "Show in and out records by date of the month",
  unrevealed:"Unrevealed",
  waiting:"Waiting",
  received:"Received",
  denied:"Refused",
  all:"All",
  those_present:"Present",
  released:"Released",
  missing:"Missing",
  with_reason:"With reason",
  without_reason: "Without a reason",
  online:"Online",
  confirm:"Confirmed",
  approved:"Approved",
  rejected:"Rejected",
  date_time:"Date and time",
  performance_type:"Performance type",
  qty:"Quantity",
  details:"Details",
  permis:"permission",
  parent_permission:"Parent permission",
  translated_resolution:"Translated resolution",
  children_permission:"Children Permission",
  children_translated_permission: "Translated children permission",
  telegram_password: "Telegram password",
  last_active:"Last activity",
  subordinates:"subordinates",
  document:"Documents",
  file:"Files",
  additional_information:"Additional information",
  work_history:"Work history",
  cancel:"Cancel",
  personal:"Personal",
  limit_work_time:"Work rate (hour)",
  actual_working_time:"Actual working time (hour)",
  salary_rotation:"Salary and Rotations",
  rotation:"Rotations",
  quality_control:"Quality control",
  process:"Process",
  name_process:"Process name",
  norms:"Normal",
  fact:"Fact",
  assigned_salary:"Assigned salary",
  last_month:"last month",
  current_month: "Current month",
  allowed:"Allowed",
  rejected:"Rejected",
  request_time:"Request time",
  licenses_and_certificates: "Licenses and Certificates",
  gave_out:"Gived",
  issued:"Issued",
  release_date:"Release Date",
  health_information:"Health information",
  growth:"growth",
  weight:"Weight",
  blood_type:"Blood type",
  locations_health_information: "Locations Health Information",
  foreign_languages:"Foreign languages",
  degree:"Degree",
  lang:"Language",
  mobil_number: "Mobile number",
  work_phone:"Work Phone",
  facebook_url:"Facebook URL:",
  instagram_url:"Instagram URL:",
  twitter_url:"Twitter URL:",
  linkedIn_url:"LinkedIn URL:",
  telegram_username:"Telegram username",
  tiktok_username:"Tiktok username",
  educational_institution: "Educational institution",
  faculty:"Faculty",
  beginning_date:"Start date",
  expiration_date:"Expiration date",
  skill:"Skill",
  not_coming_day: "Not coming day",
  hours_or_more:"{m} hours or more",
  less_than_hours: "Less than {m} hours",
  day_absence: "Day off",
  not_come_work_day:"Ishga kelmagan kun",
  complaints:"Complaints",
  subject_complaint:"Subject of the complaint",
  izn:"izn",
  date_of_departure:"Departure date",
  place_work: "Place of work",
  place_work_name: "Name of the place of work",
  data_from_to:"Date from and to",
  display_and_wallpaper: "Desktop & Screensaver",
  dynamic_wallpaper: "Dynamic Wallpaper",
  notes:"Notes",
  from_data:"Date from",
  to_data:"Date to",
  add_document:"Add document",
  labor_contract:"Labor contract",
  material_liability: "Material Liability",
  confidentiality_agreement:"Confidentiality Agreement",
  employment_contract:"Employment contract termination",
  finance_training: "Finance training",
  plastic_card:"Plastic card",
  card_number: "Card number",
  date_of_expiry:"Expiry date",
  card_organ: "Card Organ",
  amount_per_hour: "Amount per hour",
  unofficial_amount:"Unofficial amount",
  official_amount: "Official amount",
  type_salary:"Salary Type",
  amount_money: "Amount of money",
  in_hour:"In the hour",
  access_control:"access control",
  id_employee:"Employee ID",
  contact:"Contact",
  add_skill:"Add Skill",
  Level:"Level",
  // =================
  country_passport: "Country Passport",
  Send_Manager:"Send Manager",
  departure_time:"Departure time",
  access_time:"Access time",
  model:"Model",
  izn_password:"Bot password",
  sort_panel: "Dashboard",
  schedule:'Location',
  new_version:'New version',
  old_version:'Old Version',
  causes_operating_system:'Operating system causes',
  active_machines:'Company Frequently Used Machines',
  visitors_to_company_employees:'Visitors to company employees',
  number_of_visitors_periods:'Number of visitors by period',
  late_to_works:'Late employees',
  coming_date_time:'Date and time of arrival',
  coming_time:'Arrival time',
  in_count:'Number of inputs',
  out_count:'Outputs',
  total_toilet_minutes:'Total time',
  info_tms_statistic:'Recent Arrivals|Departures of goods',
  info_toilet_statistic:'Top 20 toilet users',
  director:"Director",
  country:"Country",
  logo:"Logo",
  web_site:"Website",
  application:"Application",
  explanatoryLetter:"Explanatory",
  explanatory_letters:"Explanatory",
  active_works:"Active Employee | Clean Area",
  active_work:"Active Employee | Clean Area",
  active_person:"Active Employee",
  prosperous_place: "Clean Place",
  parent_company_id: "Parent company",
  manual:"Created by manual",
  leader:"Director",
  numeric_currency_code:'Currency code',
  letter_currency_code:'Currency code',
  payed:"Payed",
  advance_salary:"Advance",
  advance_salaries:"Advance",
  advance_request: "Advance Requests",
  pentaly_salary:"Penalties",
  explanatory_letters: "explanatory letters",
  application_letter: "Application letters",
  applications: "Application letters",
  prizes: "Prizes",
  hour:'h',
  minut: 'm',
  dateuvalen: "Date of dismissal",
  enoughStaff: "Not enough staff",
  in_work: "At work",
  not_come_work: "Didn't come",
  sick_leave: "On sick leave",
  on_vacation: "On vacation",
  business_trip: "On a business trip",
  late_to_work: "latecomers",
  accepted_take_assent: "Take off",
  not_reason:'No reason',
  Break: 'Break',
  sanction: 'Sanction',
  new_sanctions: 'New sanctions',
  sanction_name: "sanction name",
  dosnt_have_salary:'Ish haqqi kiritilmaganlar',
  have_salary:'Ish haqqi kiritilganlar', 
  organizations:'Организация',
  value:"Value",
  percent:"Percent",
  value_type:"Value type",
  bonus_penalty_reasons:'Причины санкции | бонусы',
  work_times:'Working hours',
  is_free:"Свободное",
  online_works:'Online works',
  pnfl:"PINFL"


}
