import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import "@/utils/permissions";
import router from './router'
import store from './store'
import "./directive/index";
import VueApexCharts from 'vue-apexcharts'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
import "../public/css/main.css";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "@/utils/library";
// import number from "@coders-tm/vue-number-format";
Vue.use(PerfectScrollbar);
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import "../public/fonts/fonts/fontawsome/css/all.min.css";
import { i18n } from '@/utils/i18n';
import excel from 'vue-excel-export';
import { library } from '@fortawesome/fontawesome-svg-core';
import  '@/utils/v-can';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VueEditor } from "vue2-editor";
import { faUserSecret, faSackDollar, faCalendarDays } from '@fortawesome/free-solid-svg-icons'
library.add(faUserSecret,faSackDollar,faCalendarDays)

Vue.component('font-awesome-icon', FontAwesomeIcon)
 


var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0"); 
});
// Vue.use(number, { precision: 4 });
Vue.use(excel)
Vue.use(VueEditor)

Vue.use(ElementUI, { locale });
const files = require.context('./components/', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
import Inputmask from "inputmask";
Vue.directive("mask", {
  bind: function (el, binding) {
    Inputmask(binding.value).mask(el.getElementsByTagName("INPUT")[0]);
  },
});
Vue.config.productionTip = false



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')



 
