<template>
  <aside :class="[menu ? 'is-expanded' : '', mode ? 'menuday' : 'menunight']">
      <div class="main__box">
          <div class="box__menu" :class="[fixed ? 'all__size' : '', menu ? 'opened__full' : '', mode ? 'box__menuday' : 'box__menunight']">
            <div @click="closeMenu" class="menu">
              <router-link  tag="a" to="/" :class="[menu ? 'button' : 'buttonclose', $route.name == 'Home' ? 'active__main' : '']" class="button__fly">
                <span  class="navigation__list" :class="[mode ? 'iconsday' : 'iconsnight']"><span  class="fa-solid fa-chart-simple icon__fontawesome"></span></span>
                <transition name="slide-fade">
                  <div class="close__text" v-if="menu" :class="[mode ? 'text' : 'textnight', menu ? 'text__show' : 'text__hidden']">{{$t('message.desktop')}}</div>
                </transition>
              </router-link>
            </div>
            <div v-for="buy in findPath" :key="buy.id" class="menu" :class="buy.listOne && menu ? 'open__list' : ''" v-can-or="buy.is_show">
                <a @click="menu ? buy.listOne = !buy.listOne : [openToggle, buy.open = !buy.open, fixed = !fixed]" class="button__fly" :class="[menu ? 'button' : 'buttonclose', buy.listOne && buy.items ? 'hover' : '', buy.active ? 'active__main' : false]" @mouseover="[buy.open = true, fixed = true]" @mouseleave="[buy.open = false, fixed = false]">
                  <div class="navigation__list" :class="[mode ? 'iconsday' : 'iconsnight']">
                    <span class="icon__fontawesome" :class="buy.icon"></span>
                  </div>
                  <transition name="slide-fade">
                    <div class="close__text" v-if="menu" :class="[mode ? 'text' : 'textnight', menu ? 'text__show' : 'text__hidden']">{{ $t(`${buy.name}`) }}</div>
                    <div @click="closeSub(buy.open)" v-if="buy.open" class="submenu" >
                      <ul class="submenu__ul">
                        <div class="submenu__ul1"  :class="mode ? 'submenu__ul1day' : 'submenu__ul1night'">
                          <li class="submenu__title" :class="mode ? 'submenu__titleday' : 'submenu__titlenight'">{{$t(`${buy.name}`) }}</li>
                          <router-link  tag="li"  :to="{ name: l.link, params: {warehouse_type: l.params} }" v-for="l in buy.items" 
                              :key="l.id" v-can="l.is_show" class="submenu__item" :class="[mode ? 'submenu__itemday' : 'submenu__itemnight', $route.name == l.link ? 'breadtext__active' : '']">
                              {{ $t(`${l.name}`) }}
                          </router-link>
                        </div>
                      </ul>
                    </div>
                  </transition>
                </a>
                <router-link   v-if="buy.listOne && menu" :class="buy.listOne ? 'hover' : ''"  class="sub__button" tag="a" :to="{ name: n.link, params: {id: n.params} }" v-for="(n, index) in buy.items" :key="index" v-can="n.is_show"> 
                  <div @click="closeMenu" :class="[mode ? 'text' : 'textnight', $route.name == n.link ? 'breadcrumb__active' : '']">
                    <span class="bradcrumb__icon" :class="mode ? 'icons__sizeday' : 'icons__sizenight'">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor"/>
                      </svg>
                    </span>
                    <span class="text__size"> {{ $t(`${n.name}`) | truncate(21, '...') }}</span>
                  </div>
                </router-link>
            </div>
          </div>
      </div>
      <div class="show__fixed" :class="[mode ? 'show__fixedday' : 'show__fixednight', !menu ? 'show__withclose' : 'show__withopen']"></div>
  </aside>
</template>
<script>
export default {
data: () => ({
  list: [
      {
          link: false,
          name: 'message.recruiting',
          tooltip: 'Dashboard',
          icon: 'fa-solid fa-user-plus',
          is_show:['VacancyController@index','CandidateController@index'],
          open: false,
          listOne: false,
          items: [
              {
                  name: 'message.vacancy',
                  link: 'vacancy',
                  is_show:'VacancyController@index'
              },
              {
                  name: 'message.candidates',
                  link: 'candidates',
                  is_show: 'CandidateController@index'
              }
          ]
      },
      {
          link: '/purchase',
          name: 'message.staff',
          tooltip: 'User',
          icon: 'fa-solid fa-user-group',
          open: false,
          is_show:['StaffController@index'],
          listOne: false,
          items: [
              {
                  name: 'message.staffTest',
                  link: 'staff', 
                  is_show:'StaffController@index',
              },
              {
                  name: 'message.operating',
                  link: 'current',
                  is_show:'StaffController@index',
              },
              {
                  name: 'message.dismissed',
                  link: 'dismissed',
                  is_show:'StaffController@index',
              }
          ]
      },
      {
          link: '#',
          name: 'message.finance',
          tooltip: 'Messages',
          icon: 'fa-solid fa-sack-dollar',
          open: false,
          is_show:[ 
                    'TransactionController@index',
                    'PaymentsExpenseController@index',
                    'PenaltyController@getStaffPenalty',
                    'AccountController@index',
                    'BonusController@getStaffBonus',
                    'AskAssentController@advanceSalaries'
                  ],
          listOne: false,
          items: [
              {
                  name: 'message.o_salary',
                  link: 'transaction',
                  is_show:'TransactionController@index'
              },
              {
                  name: 'message.payments_expenses',
                  link: 'payments_expenses',
                  is_show:'PaymentsExpenseController@index'
              },
              {
                  name: 'message.sanction',
                  link: 'penalties',
                  is_show:'PenaltyController@getStaffPenalty'
              },
              {
                  name: 'message.check_a',
                  link: 'accounts',
                  is_show:'AccountController@index'
              },
              {
                  name: 'message.bonuses',
                  link: 'bonuses',
                  is_show:'BonusController@getStaffBonus'
              },
              {
                  name: 'message.vacation_management',
                  link: 'vacationManagement',
                  is_show:'VacationManagementController@index'
              },
          ]
      },
      {
          link: '#',
          name: 'message.KPP',
          tooltip: 'Analytics',
          icon: 'fa-solid fa-road-barrier',
          open: false,
          is_show:[
                    'VisitorController@index',
                    'GuestCarController@index',
                    'GuestController@index',
                    'TmsController@index',
                    'EmployeeKppController@index',
                    'OperationController@index',
                    'TransportController@index',
                    'LateToWorkController@index'
                  ],
          listOne: false,
          items: [
              {
                  name: 'message.visitor',
                  link: 'visitor',
                  is_show:'VisitorController@index'
              },
              {
                  name: 'message.client_transport',
                  link: 'guest_car',
                  is_show:'GuestCarController@index'
              },
              {
                  name: 'message.guest',
                  link: 'guest',
                  is_show:'GuestController@index'
              },
              {
                  name: 'message.tms',
                  link: 'tms',
                  is_show:'TmsController@index'
              },
              {
                  name: 'message.employee_kpp',
                  link: 'employee_kpp',
                  is_show:'EmployeeKppController@index'
              },
              {
                  name: 'message.operation',
                  link: 'operation',
                  is_show:'OperationController@index'
              },
              {
                  name: 'message.company_machines',
                  link: 'service',
                   is_show:'TransportController@index'
              },
              {
                  name: 'message.late_to_works',
                  link: 'lateToWorks',
                  is_show:'LateToWorkController@index'
              },
          ]
      },
      {
          link: '#',
          name: 'message.request',
          tooltip: 'Saved',
          icon: 'fa-solid fa-paper-plane',
          open: false,
          is_show:[
                    'AskAssentController@advanceSalaries',
                    'AskAssentController@takeAssent',
                    'AskAssentController@vacation',
                    'AskAssentController@complaint',
                    'AskAssentController@additional',
                    'AskAssentController@onlineWorks',
                    'AskAssentController@application',
                    'AskAssentController@explanatoryLetter',
                    'AskAssentController@activeworks',
                    'AskAssentController@getTelegramAnswerMessage',
                    'TelegramQuizzesController@index'
                ],
          listOne: false,
          items: [
              {
                name: 'message.avance',
                link: 'advanceSalary',
                is_show:'AskAssentController@advanceSalaries'
              },
              {
                name: 'message.leave_requests',
                link: 'takeAssent',
                is_show:'AskAssentController@takeAssent'
              },
              {
                name: 'message.vacation',
                link: 'vacation',
                is_show:'AskAssentController@vacation'
              },
              {
                name: 'message.sick_leaves',
                link: 'sickLeave',
                is_show:'AskAssentController@sickLeaves'
              },
              {
                name: 'message.complaint',
                link: 'complaint',
                is_show:'AskAssentController@complaint'
              },
              {
                name: 'message.additional_work',
                link: 'additionalWork',
                is_show:'AskAssentController@additional'
              },
              {
                name: 'message.online_works',
                link: 'onlineWork',
                is_show:'AskAssentController@onlineWorks'
              },
              {
                name: 'message.application',
                link: 'application',
                is_show:'AskAssentController@application'
              },
              {
                name: 'message.explanatoryLetter',
                link: 'explanatoryLetter',
                is_show:'AskAssentController@explanatoryLetter'
              },
              {
                name: 'message.active_works',
                link: 'activework',
                is_show:'AskAssentController@activeworks'
              },
              {
                name: 'Телеграм Staff бот',
                link: 'telegramAnswerMessage',
                is_show:'AskAssentController@getTelegramAnswerMessage'
              },
              {
                name: 'message.telegram_quiz',
                link: 'telegramQuiz',
                is_show:'TelegramQuizzesController@index'
              },
          ]
      },
      {
          link: '#',
          name: 'message.indicators',
          tooltip: 'Files',
          icon: 'fa-solid fa-chart-line',
          is_show:[
                    'AttendanceController@index',
                    'StructureController@structure',
                    'AttendanceReportController@getReports',
                    'AttendanceReportController@getReports',
                    'AttendanceReportDepartmentController@getReports',
                    'BusinessTripController@index',
                  ],
          open: false,
          listOne: false,
          items: [
            {
              name: 'message.journal',
              link: 'journal',
              is_show:'AttendanceController@index'
            },
            {
              name: 'message.structure',
              link: 'structure',
              is_show:'StructureController@structure'
            },
            // {
            //   name: 'message.nv_monitoring',
            //   link: 'monitoring',
            //   is_show:'AskAssentController@advanceSalaries'
            // },
            // {
            //   name: 'З/П',
            //   link: 'wage',
            //   is_show:'AskAssentController@advanceSalaries'
            // },
            //  {
            //   name: 'message.report_card',
            //   link: 'attendance_report',
            //   is_show:'AttendanceReportController@getReports'
            // },
            {
              name: 'message.report_card',
              link: 'attendance_report_new',
              is_show:'AttendanceReportController@getReports'
            },
            {
              name: 'message.attendance',
              link: 'attendance',
              is_show:'AttendanceReportDepartmentController@getReports'
            },
            {
              name: 'message.business_trips',
              link: 'business_trips',
              is_show:'BusinessTripController@index'
            },
            
          ]
      },
      {
          link: '#',
          name: 'message.access_control',
          tooltip: 'Order',
          icon: 'fa-solid fa-user-gear',
          is_show:[
                    'UserController@index',
                    'RoleController@index',
                    'PermissionController@index',
                    'UserActivityController@index'
                  ],
          open: false,
          listOne: false,
          items: [
            {
              name: 'message.users',
              link: 'users',
              is_show:'UserController@index'
            },
            {
              name: 'message.role',
              link: 'roles',
              is_show:'RoleController@index'
            },
            {
              name: 'message.permission',
              link: 'permissions',
              is_show:'PermissionController@index'
            },
            {
              name: 'message.user_activity',
              link: 'user_activity',
              is_show:'UserActivityController@index'
            }
          ]
      },
      {
          link: '#',
          name: 'message.settings',
          tooltip: 'Order',
          icon: 'fa-solid fa-gear',
          is_show:[
            'GraphicTypeController@index',
            'GraphicController@index',
            'DepartmentController@index',
            'BranchController@index',
            'CompanyController@index',
            'PositionController@index',
            'PriorityController@index',
            'RegionController@index',
            'OrganizationController@index',
            'DistrictController@index',
            'PenaltyReasonController@index',
            'ReasonController@index',
            'ReasonTypeController@index',
            'RequirementController@index',
            'TypeExpenseController@index',
            'CurrencyController@index',
            'NationController@index',
            'StaffWorkTypeController@index',
            'LanguageController@index',
            'SkillController@index',
            'SocialController@index',
            'EducationController@index',
            'StaffAssentReasonController@index',
            'CategoryController@index',
            'VacationManagementController@index',
            'TelegramManagerController@index'
          ],
          open: false,
          listOne: false,
          items: [
            {
              name: 'message.graphicType',
              link: 'graphicType',
              is_show:'GraphicTypeController@index'
            },
            {
              name: 'message.graphic',
              link: 'graphic',
              is_show:'GraphicController@index'
            },
            {
              name: 'message.department_title',
              link: 'department',
              is_show:'DepartmentController@index'
            },
            {
              name: 'message.branch',
              is_show:'BranchController@index',
              link: 'branch'
            },
            {
              name: 'message.company',
              is_show:'CompanyController@index',
              link: 'company'
            },
            {
              name: 'message.position',
              is_show:'PositionController@index',
              link: 'position'
            },
            {
              name: 'message.priority',
              is_show:'PriorityController@index',
              link: 'priority'
            },
            {
              name: 'message.region',
              is_show:'RegionController@index',
              link: 'region'
            },
            {
              name: 'message.organizations',
              is_show:'OrganizationController@index',
              link: 'organization'
            },
            {
              name: 'message.district',
              is_show:'DistrictController@index',
              link: 'district'
            },
            {
              name: 'message.bonus_penalty_reasons',
              is_show:'PenaltyReasonController@index',
              link: 'penalty_reasons'
            },
            {
              name: 'message.reason',
              is_show:'ReasonController@index',
              link: 'reason'
            },
            {
              name: 'message.reasontype',
              is_show:'ReasonTypeController@index',
              link: 'reasonType'
            },
            {
              name: 'message.requirements',
              is_show: 'RequirementController@index',
              link: 'requirements'
            },
            {
              name: 'message.type_expenses',
              is_show:'TypeExpenseController@index',
              link: 'type_expense'
            },
            {
              name: 'message.language2',
              link: 'language',
              is_show:'LanguageController@index'
            },
            {
              name: 'message.currency',
              link: 'currency',
              is_show:'CurrencyController@index'
            },
            {
              name: 'message.skill',
              link: 'skill',
              is_show:'SkillController@index'
            },
            {
              name: 'message.nation',
              link: 'nation',
              is_show:'NationController@index'
            },
            {
              name: 'message.social',
              link: 'social',
              is_show:'SocialController@index'
            },
            {
              name: 'message.education',
              link: 'education',
              is_show:'EducationController@index'
            },
            {
              name: 'message.staff_work_type',
              link: 'staffWorkType',
              is_show:'StaffWorkTypeController@index'
            },
            {
              name: 'message.work_type',
              link: 'workType',
              is_show:'WorkTypeController@index'
            },
            {
              name: 'message.staffAssentReason',
              link: 'staffAssentReason',
              is_show:'StaffAssentReasonController@index'
            },
            {
              name: 'message.category',
              link: 'category',
              is_show:'CategoryController@index'
            },
            {
              name: 'message.vacation_graphics',
              link: 'vacationGraphic',
              is_show:'VacationManagementController@index'
            },
            {
              name: 'Телеграм сообщение',
              link: 'telegramManager',
              is_show:'TelegramManagerController@index'
            },
            // {
            //   name: 'message.vacation_planes',
            //   link: 'vacationPlane',
            //   is_show:'VacationManagementController@index'
            // },
            {
                name: 'message.anecdotes',
                link: 'anecdote',
                is_show:'AnecdoteController@index'
            },
            {
              name: 'message.question_category_list',
              link: 'questionCategoryList',
              is_show:'QuestionCategoryListController@index'
            },
            {
              name: 'message.question_direction_list',
              link: 'questionDirectionList',
              is_show:'QuestionDirectionListController@index'
            },
            {
              name: 'message.questions',
              link: 'question',
              is_show:'QuizQuestionController@index'
            },
            {
              name: 'message.quiz_plan',
              link: 'quizPlan',
              is_show:'QuizPlanController@index'
            },
            {
              name: 'message.sanction_type',
              link: 'sanctionType',
              is_show:'QuizPlanController@index'
            },

          ]
      },
      {
          link: '#',
          name: 'ACMS',
          tooltip: 'Saved',
          icon: 'fa-solid fa-microchip',
          open: false,
          is_show:[
                    'SecurityLocationController@index',
                    'DeviceTypeController@index',
                    'DeviceController@index',
                ],
          listOne: false,
          items: [
              {
                  name: 'message.security_location',
                  link: 'securityLocation',
                  is_show:'SecurityLocationController@index'
              },
              {
                  name: 'message.deviceType',
                  is_show:'DeviceTypeController@index',
                  link: 'deviceType'
              },
              {
                  name: 'message.device',
                  is_show:'DeviceController@index',
                  link: 'devices'
              }
          ]
      },
      {
          link: '#',
          name: 'message.calendar',
          tooltip: 'Setting',
          icon: 'fa-solid fa-calendar-days',
          open: false,
          is_show:['CalendarController@index'],
          listOne: false,
          items: [
              {
                  name: 'message.calendar',
                  is_show:'CalendarController@index',
                  link: 'calendar'
              }
          ]
      }
  ],
  fixed: false,
  windowWidth: window.innerHeight,
}),
computed: {
  menu () {
    return this.$store.getters.MENU
  },
  mode () {
    return this.$store.getters.MODE
  },
  findPath () {
    this.list.map(item => {
      const info = item.items.map(n => {
        return n.link
      })
      Object.assign(item, {active: info.indexOf(this.$route.name) >= 0});
    })
    return this.list
  }
},
mounted() {
this.windowWidth = screen.width
  if (screen.width <= 600) {
    console.log("WORRRRIIIINNNN");
  } else {
  }
},
methods: {
  ToggleMenu () {
      this.is_expanded = !this.is_expanded
  },
  openToggle () {
      this.$store.commit('toggleMenu')
  },
  closeSub (item) {
      item = false
      this.fixed = true
  },
  closeMenu() {
    if(this.windowWidth <= 600) {
      this.$store.commit('toggleMenu')
    }
    
  }
},
filters: {
  truncate: function (text, length, suffix) {
      if (text.length > length) {
          return text.substring(0, length) + suffix
      } else {
          return text
      }
  }
}
}
</script>
<style>
  /* :root {
      --primary: #0d67c9;
      --grey: #64748b;
      --dark: #1e293b;
      --dark-alt: #334155;
      --light: #f1f5f9;
      --sidebar-width: 300px;
  } */
  aside {
      display: flex;
      flex-direction: column;
      width: calc(2rem + 28px);
      min-height: 100%;
      max-height: 100%;
      overflow: hidden;
      padding: 0rem 1rem 0rem 1rem;
      color: #f1f5f9;
      position: relative;
      z-index: 1001;
      transition: all .3s ease !important;
  }
  .all__size {
      width: 100%;
  }
  .menuday {
      background-color: #fff;
  }
  .menunight {
      background: #100e1d;
  }
  .logo {
      margin-bottom: 1rem;
  }
  .logo img {
      width: 2rem;
  }

  .menu-toggle-wrap {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1rem;
      position: relative;
      transition: opacity .3s ease !important;
      top: 0;
  }
  .sub__button:hover .text__size {
      color: #0d67c9;
  }
  .sub__button:hover .icons__sizeday svg path {
      fill: #0d67c9;
  }
  .sub__button:hover .icons__sizenight svg path {
      fill: #0d67c9;
  }

  .menu-toggle {
      transition: opacity .3s ease !important;
  }
  .main__box {
      display: flex;
      width: 100%;
      position: relative;
      height: 100%;
  }
  /* .menu-toggle:hover .material-icons i {
      color: #fff;
      transition: opacity .1s ease-out;
      transform: translateX(0.5rem);
  } */
  .navigation__list {
    width: 30px;
    margin-left: -3px;
    display: flex;
    text-align: center;
  }
  .navigation__list span {
      transition: all .3s ease;
      text-align: center;
      margin-top: 0px;
      text-align: center;
  }
  .show__fixed {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      z-index: -2;
  }
  .show__fixedday {
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  }
  .show__fixednight {
      background: #100e1d;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  }
  .show__withclose {
      width: calc(2rem + 28px);
      -webkit-transition: all .3s !important;
      transition: all .3s !important;
  }
  .show__withopen {
      width: 230px;
      -webkit-transition: all .3s !important;
      transition: all .3s !important;

  }
  .button__fly {
    position: relative;
    border-radius: 10px;
  }
  .button__fly:hover .navigation__list span {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to bottom, #0d67c9, #0d67c9, #0d67c9, #0d67c9, #0d67c9);background-image: linear-gradient(to bottom, #0d67c9, #0d67c9, #0d67c9, #0d67c9, #0d67c9);
  }
  .button__fly:hover .text {
      color: #0d67c9;
  }
  .button__fly:hover .textnight {
      color: #0d67c9;
  }
  .button__fly:hover {
    background: #f8f6f2;
    border-radius: 10px;
  }
  .active__main:hover .textnight {
    color: #fff !important;
  }
  /* .material-icons span::before {
      font-size: 24px;
      font-weight: 400;
  } */
  .iconsday {
      color: #030303;
  }
  .iconsnight {
      color: #fff;
  }
  .is-expanded {
      width: 230px;
      -webkit-transition: all .3s ease !important;
      transition: all .3s ease !important;

  }
  .submenu__item:before {
      margin-top: 7px;
      content: "";
      width: 5px;
      height: 5px;
      margin-left: -8px;
      margin-right: 5px;
      background-color: #bdc5d1;
      opacity: 1;
      border-radius: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      transition: opacity .0s;
      z-index: 1;
  }
  .submenu__item:hover::before {
    background-color: #0d67c9;
  }

  .menu-toggle {
      top: -3rem;
      margin-right: -18px;
  }
  .box__menu {
      position: fixed;
      left: 0;
      padding-right: 0px;
      padding-top: 0px;
      height: 92%;
      overflow-y: scroll;
      overflow-x: hidden;
      direction: rtl;
      padding-bottom: 150px;
      -webkit-transition: all .3s ease !important;
      transition: all .3s ease !important;

  }
  .opened__full {
      width: 230px;
      -webkit-transition: all .3s ease !important;
      transition: all .3s ease !important;

  }
  
  .box__menuday::-webkit-scrollbar {
      width: 3px;
  }
  .box__menuday {
      scrollbar-width: thin;
      scrollbar-color: #d0d6e3;
  }
  .box__menuday::-webkit-scrollbar-track {
      background: #ffffff;
  }
  .box__menuday::-webkit-scrollbar-thumb {
      background-color: #283046;
      border-radius: 10px;
      border: 2px solid #fff0;
  }
  .box__menunight::-webkit-scrollbar {
      width: 3px;
  }
  .box__menunight {
      scrollbar-width: thin;
      scrollbar-color: #5f5f5f;
  }
  .box__menunight::-webkit-scrollbar-track {
      background: #1f1f1f;
  }
  .box__menunight::-webkit-scrollbar-thumb {
      background-color: #283046;
      border-radius: 10px;
      border: 2px solid #fff0;
  }
  .menu {
      direction: ltr;
  }
  .button {
      width: 215px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none !important;
      margin: 10px 5px 10px 5px;
      padding: 0.6rem 45px 0.6rem 10px;
      cursor: pointer !important;
      -webkit-transition: all .3s ease !important;
      transition: all .3s ease !important;

  }
  .active__main {
    background: #0d67c9;
    border-radius: 10px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  }
  .active__main .text {
    color: #fff;
  }
  .active__main:hover {
    border-color: #0d67c9;
    background-color: #0d67c9 !important;
  }
  .active__main:hover .text {
    color: #fff;
  }
  .active__main:hover .navigation__list span {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to bottom, #fff, #fff, #fff, #fff, #fff);background-image: linear-gradient(to bottom, #fff, #fff, #fff, #fff, #fff);
  }
  .buttonclose {
      width: 45px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none !important;
      padding: 0.6rem 35px 0.6rem 10px;
      margin: 10px 5px 10px 5px;
      transition: opacity .0s ease;
      cursor: pointer;
  }
  .sub__button {
      width: 221.5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none !important;
      padding: 4px 0;
      cursor: pointer;
      transition: opacity .1s;
  }
  .activen {
      border-left: 5px solid #f06445;
  }

  .text {
      display: flex;
      align-items: center;
      color: #92929f;
      font-size: 15px;
      font-weight: bold;
      padding-top: 0px;
      margin-left: 3px;
  }
  .textnight {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      padding-top: 0px;
      margin-left: 3px;
  }

  .material-icons {
      margin-right: 10px;
      display: flex;
      align-items: center;
  }
  .submenu {
      position: relative;
      z-index: 999999999999 !important;
      padding: 10px 0px;
  }
  .submenu__ul {
      display: flex;
      flex-direction: row;
      padding-left: 0;
      position: absolute;
      z-index: 99999999 !important;
      left: 0;
      margin-top: -22px;
      min-width: 350px;
      max-width: 400px;
      text-align: left;
      margin-left: 0px;
  }
  .submenu__ul1 {
    min-width: 200px;
    margin-left: 20px;
    padding: 5px 0 10px 0;
    overflow-y: auto;
    margin-right: 15px;
    padding-right: 25px;
    direction:ltr;
    max-height: 250px;
  }

  .submenu__ul1day {
      border-radius: 10px;
      background: #fff;
      color: #030303;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
  .submenu__ul1night {
    border-radius: 10px;
      background: #100e1d;
      color: #fff;
      box-shadow: rgba(230, 230, 230, 0.02) 0px 1px 3px 0px, rgba(80, 86, 92, 0.15) 0px 0px 0px 1px;
  }
  .submenu__ul1day::-webkit-scrollbar {
    width: 8px;
  }
  .submenu__ul1day {
    scrollbar-width: thin;
    scrollbar-color: #ebeef5;
  }
  .submenu__ul1day::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }
  .submenu__ul1day::-webkit-scrollbar-thumb {
    background-color: #ebeef5;
    border-radius: 10px;
    border: 2px solid #fff0;
  }
  .submenu__ul1day:hover::-webkit-scrollbar-thumb {
    background-color: #d1d7e4;
    border-radius: 10px;
    border: 2px solid #fff0;
  }

  .submenu__ul1night::-webkit-scrollbar {
    width: 8px;
  }
  .submenu__ul1night {
    scrollbar-width: thin;
    scrollbar-color: #7a7a7a;
  }
  .submenu__ul1night::-webkit-scrollbar-track {
    background: #828282;
    border-radius: 10px;
  }
  .submenu__ul1night::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    border-radius: 10px;
    border: 2px solid #fff0;
  }
  .submenu__ul1night:hover::-webkit-scrollbar-thumb {
    background-color: #d1d7e4;
    border-radius: 10px;
    border: 2px solid #fff0;
  }
  .submenu__item {
      display: flex;
      font-size: 14px;
      font-weight: bold;
      padding: 5px 10px 5px 20px;
      border-radius: 10px;
      cursor: pointer;
      transition: opacity .1s ease-out;
  }
  .submenu__item:hover {
      color: #0d67c9;
  }
  .breadtext__active {
    color: #0d67c9;
  }
  .breadtext__active::before {
    background-color: #0d67c9;
  }
  .submenu__title {
      display: flex;
      font-size: 16px;
      font-weight: 800;
      padding: 5px 10px 0px 10px;
      margin-bottom: 5px;
      border-radius: 10px;
  }
  .icon__fontawesome {
    font-size: 25px;
    width: 40px;
    color: #bdc5d1;
  }
  .icons__sizeday svg {
      width: 18px;
      height: 18px;
  }
  .icons__sizeday svg path {
      fill: #bdc5d1;
  }

  .icons__sizenight svg {
      width: 18px;
      height: 18px;
  }
  .icons__sizenight svg path {
      fill: #fff;
  }
  .text__size {
      width: 190px;
      font-size: 14px;
      padding-top: 2px;
      text-decoration: none !important;
  }
  .sub__icon {
      margin-left: 2px;
  }
  .breadcrumb__active .text__size {
    color: #0d67c9;
    border-color: #0d67c9;
  }
  .breadcrumb__active .bradcrumb__icon svg path {
    fill: #0d67c9;
  }
  .sub__icon svg {
      width: 24px;
      height: 24px;
  }
  .text__show {
    position: absolute;
    margin-left: 35px;
    width: 175px;
    height: 40px;
  }
  .active__main .icon__fontawesome {
    color:  #fff !important;
  }

  .slide-fade-enter-active {
          transition: opacity .3s ease !important;
      }
      .slide-fade-leave-active {
          transition: opacity 0.3s ease-in cubic-bezier(1.0, 0.5, 0.8, 1.0);
      }
      .slide-fade-enter, .slide-fade-leave-to
      /* .slide-fade-leave-active до версии 2.1.8 */ {
          transform: translateX(-220px);
          opacity: 0;
  }
</style>
