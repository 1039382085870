import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    staff : {
        show: true,
        title: i18n.t('message.staff'),
        sortable: true,
        column: 'staff'
    },
    branch : {
      show: true,
      title: i18n.t('message.branch'),
      sortable: true,
      column: 'branch'
    },
    department : {
      show: true,
      title: i18n.t('message.department'),
      sortable: true,
      column: 'department'
    },
    position : {
      show: true,
      title: i18n.t('message.position'),
      sortable: true,
      column: 'position'
    },
    peryot_year : {
      show: true,
      title: i18n.t('Период Год'),
      sortable: true,
      column: 'month'
    },
    peryot_date : {
      show: true,
      title: i18n.t('Период День'),
      sortable: true,
      column: 'month'
    },
    comment : {
      show: true,
      title: i18n.t('message.comment'),
      sortable: true,
      column: 'comment'
  },
    status : {
      show: true,
      title: i18n.t('message.status'),
      sortable: true,
      column: 'status'
  },
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    // settings : {
    //     show: true,
    //     title: i18n.t('message.settings'),
    //     sortable: false,
    //     column: 'settings'
    // },

};
